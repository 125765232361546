import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, message } from 'antd'
import { uniq } from 'lodash'

import convertValuesToInput from '@/components/Booking/NewBookingForm/SubmitAction/convertValuesToInput'
import { collectFiles } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import makeBookingGql from '@/components/Booking/NewBookingForm/SubmitAction/schema'
import config from '@/config'
import useJobTypes from '@/hooks/useJobTypes'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'
import responseHandler from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

const SubmitAction = memo(
  ({ form, client, postSubmitAction = () => {}, isOverrideBlockDuplicate }: any) => {
    const { t } = useTranslation()

    const { requestJobTypes, jobTypes } = useJobTypes(client)

    const [isUploadingDoc, setIsUploadingDoc] = useState(false)

    const [makeBooking, { loading }] = useMutation(makeBookingGql, { client })

    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
    const pluginSplitJobsEnabled = useBookingStore.use.pluginSplitJobsEnabled()

    useEffect(() => {
      const jobTypesKeys = uniq(form.getFieldValue('jobs')?.map(job => job.type)).filter(
        Boolean
      ) as string[]
      requestJobTypes({ codes: jobTypesKeys })

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.getFieldValue('type'), form.getFieldValue('jobs')])

    const handleDocumentUpload = useCallback(
      async (acceptedFiles: File[], type: string, uuid: string) => {
        const body = new FormData()

        body.append('uuid', uuid)
        body.append('type', type)

        acceptedFiles.forEach(file => body.append('files', file))

        try {
          respHandler('Uploading document, hang on...', 'load')

          const resp = await fetch(`${config.api.baseUrl}/booking/upload-document`, {
            method: 'POST',
            headers: {
              'base-company-uuid': selectedGlobalCompany.uuid,
              authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`
            },
            body,
            // @ts-ignore
            progress: (e: { loaded: number; total: number }) => {
              console.log(`Progress: ${e.loaded / e.total}%`)
            }
          })

          const respData = await resp.json()

          if (respData.message) {
            respHandler(respData.message, 'error')
          } else {
            respHandler('Uploaded successfully.', 'success')
            return respData
          }
        } catch (err) {
          console.log(err)
          respHandler('something went wrong uploading file', 'error')
        }
      },
      [selectedGlobalCompany.uuid]
    )

    const handleClick = useCallback(() => {
      form.validateFields(async (err: any, values: any) => {
        if (!values.details) values.details = {}

        if (err) return responseHandler(t('error.missingInput'), 'error')

        setIsUploadingDoc(true)
        values.isOverrideBlockDuplicate = isOverrideBlockDuplicate
        try {
          const input = convertValuesToInput(values, jobTypes)
          if (!input) return

          const files = collectFiles(input)

          // Clean up the input data by removing file uploads
          files.forEach(({ level, key }) => {
            if (level === 'booking' && input.details) {
              delete input.details[key]
            } else if (level === 'job' && input.jobs) {
              input.jobs.forEach(job => job?.details && delete job.details[key])
            } else if (level === 'trip' && input.jobs) {
              input.jobs.forEach(job =>
                job?.trips?.forEach(trip => trip?.details && delete trip.details[key])
              )
            }
          })

          // @ts-ignore
          const { data } = await makeBooking({ variables: { input } })

          if (data?.makeBooking?.length) {
            if (pluginSplitJobsEnabled.asset) {
              await Promise.all(
                data?.makeBooking?.map((job, index) => {
                  const uuid = data.makeBooking?.[index]?.uuid
                  const findRelatedFiles = files.filter(
                    f => f.level !== 'job' && f.jobIndex !== index
                  )
                  findRelatedFiles.map(async ({ files, key }) => {
                    await handleDocumentUpload(files, key, uuid)
                  })
                })
              )
            } else {
              await Promise.all(
                files.map(async ({ files, key }) => {
                  await handleDocumentUpload(files, key, data.makeBooking?.[0]?.uuid)
                })
              )
            }
            postSubmitAction(data)
          }
        } catch (error: any) {
          logger.error('SubmitAction makeBookingGql error', error)
          if (error?.message?.includes('Not allowed to create booking as with this Company')) {
            message.error(error.message)
          } else {
            respHandler(error, 'error')
          }
        } finally {
          setIsUploadingDoc(false)
        }
      })
    }, [
      t,
      form,
      jobTypes,
      makeBooking,
      postSubmitAction,
      handleDocumentUpload,
      pluginSplitJobsEnabled.asset,
      isOverrideBlockDuplicate
    ])

    return (
      <Button
        id="submit-new-booking-button"
        type="primary"
        onClick={handleClick}
        loading={loading || isUploadingDoc}
      >
        Submit
      </Button>
    )
  }
)

export default withApollo(SubmitAction)
