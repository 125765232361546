import { memo, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useHistory } from 'react-router'
import { gql, useLazyQuery } from '@apollo/client'
import { Row, Spin } from 'antd'
import { parse } from 'query-string'

import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import LeftFilter from '../Layouts/LeftFilter'
import NoAccess from '../Shared/NoAccess'
import { FiltersView } from './Styled'

const BOOKINGS_FILTERS_QUERY = gql`
  query bookingsSideFilters($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      facets
    }
  }
`

const SideFilters = memo((props: any) => {
  const { client } = props
  const history = useHistory()
  const [input, setInput] = useState({})

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'booking',
    permission: 'index'
  })

  const [getBookingSearch, { loading, data, error }] = useLazyQuery(BOOKINGS_FILTERS_QUERY, {
    client,
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const fromURL = history.location.search.slice(1)
    const urlData: any = parse(fromURL)
    const query = {
      q: urlData?.q || '',
      sort: '',
      limit: 1,
      offset: 0,
      facets: ['status', 'isAssigned'],
      filter: urlData.filter ? JSON.parse(urlData.filter) : {}
    }
    setInput(query)

    getBookingSearch({ variables: { input: query } })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history])

  if (permLoading || loading) {
    return (
      <Row type="flex" justify="center" align="middle" style={{ height: '50vh' }}>
        <Spin />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  if (error) {
    responseHandler(error, 'error')
    logger.error('SideFilter BOOKINGS_FILTERS_QUERY error', error)
  }

  return (
    <FiltersView>
      <LeftFilter customOverflow facets={data?.bookingsSearchJson?.facets} />
    </FiltersView>
  )
})

export default withApollo(SideFilters)
