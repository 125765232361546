import { BookingType, JobType } from '@/types/graphql'

import { useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { isEmpty } from 'lodash'

import { VIEW_BOOKING_TYPE_GQL } from '@/components/Booking/General'
import DynamicTransportSelect, {
  DynamicTransportSelectProps
} from 'App/components/Select/DynamicSelector'
import { JOB_TYPES_QUERY } from 'App/graphql/booking'

interface JobTypeSelectorProps extends DynamicTransportSelectProps {
  client?: any
  bookingTypeCode?: string
  bookingTypeUuid?: string
}

const JobTypeSelector = ({ ...props }: JobTypeSelectorProps) => {
  const [bookingType, setBookingType] = useState<BookingType>({})
  const [getBookingType, { data }] = useLazyQuery(VIEW_BOOKING_TYPE_GQL, {
    client: props.client,
    fetchPolicy: 'cache-first'
  })

  const filterFunc = useCallback(
    (jobType: JobType) => {
      return bookingType?.allowedJobTypes?.length
        ? bookingType?.allowedJobTypes?.includes(jobType.code)
        : true
    },
    [bookingType?.allowedJobTypes]
  )

  useEffect(() => {
    if (!props.bookingTypeCode && !props.bookingTypeUuid) return

    const variables = {
      ...(props.bookingTypeCode && { code: props.bookingTypeCode }),
      ...(props.bookingTypeUuid && { uuid: props.bookingTypeUuid })
    }

    getBookingType({
      variables
    })
  }, [props.bookingTypeCode, props.bookingTypeUuid])

  useEffect(() => {
    if (isEmpty(data) || !data) return
    setBookingType(data.bookingType)
  }, [data])

  return (
    <DynamicTransportSelect
      {...props}
      query={JOB_TYPES_QUERY}
      filterFunc={filterFunc}
      type={'jobTypes'}
      searchText={'Select job type...'}
    />
  )
}
export default withApollo(JobTypeSelector)
