import { gql } from '@/types'

export const BOOKING_COST_ITEMS = gql(`
  query bookingCostItemsOptions($uuid: UUID) {
    booking(uuid: $uuid) {
      costItems {
        uuid
        costBaseRate
        quantity
        sellRate
        costRate
        chargeItem {
          code
          description
        }
      }
    }
  }
`)
