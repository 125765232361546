import { Steps } from 'antd'
import { useBookingStore } from 'App/store/booking'

const FormSteps = props => {
  const { currentStep } = props
  const pluginSplitJobsEnabled = useBookingStore.use.pluginSplitJobsEnabled()


  return (
    <Steps current={currentStep} size="small">
      <Steps.Step title="Shipment Information" />
      <Steps.Step title="Details" />
      <Steps.Step title="Confirmation" />
      {pluginSplitJobsEnabled.asset && <Steps.Step title="Post Submission" />}
    </Steps>
  )
}

export default FormSteps
