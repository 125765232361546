import { Icon } from 'antd'

import Dropzone from '@/components/Workflow/Required/Uploader/UploadButton/Dropzone'
import Label from '@/components/Workflow/Required/Uploader/UploadButton/Label'

type UploadButtonProps = {
  label: string
  handleUpload: Function
}

function UploadButton({ label, handleUpload }: UploadButtonProps) {
  return (
    <Dropzone onDrop={handleUpload}>
      <Icon style={{ color: '#1890ff', fontSize: '1.5rem' }} type="inbox" />
      <Label>{label}</Label>
    </Dropzone>
  )
}

export default UploadButton
