import { BookingTypeStatus, DynamicFieldControl } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'
import { Col, Form, Row } from 'antd'
import startCase from 'lodash/startCase'
import styled from 'styled-components'

import DynamicField from '@/components/Booking/DynamicField'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import DynamicTransportSelect from '@/components/Select/DynamicSelector'
import CompanySelect from '@/components/Select/TypeToFetch/CompanySelect'
import { ComponentErrorFallback } from '@/ErrorFallback'
import { useBookingStore } from '@/store/booking'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledDiv = styled.div`
  .ant-form-item-control {
    margin-bottom: 10px;
  }
`

const BOOKING_TYPES_QUERY = gql`
  query bookingTypesNewBookingForm(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BookingTypeStatus]
    $byCompany: UUID
  ) {
    bookingTypes(
      limit: $limit
      offset: $offset
      q: $q
      statuses: $statuses
      byCompany: $byCompany
    ) {
      rows {
        code
        uuid
        name
        allowedJobTypes
      }
    }
  }
`

const VIEW_BOOKING_TYPE_GQL = gql`
  query bookingTypeNewBooking($uuid: UUID, $code: String) {
    bookingType(uuid: $uuid, code: $code) {
      code
      status
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        multiple
        enumName
        customValues
        isMultiline
        isBlockDuplicate
        isCapitalize
      }
      allowedJobTypes
    }
  }
`

const ShipperConsigneeSubForm = props => {
  const { form, duplicateBookingObject = {}, mode = FormMode.Create, client, setFields } = props
  const { getFieldDecorator } = form

  const [dynamicFields, setDynamicFields] = useState([])
  const [queriedBookingType, setQueriedBookingType] = useState(null)
  const [initialValues, setInitialValues] = useState(duplicateBookingObject)

  const selectedBillToCompany = useBookingStore.use.selectedBillToCompany()
  const setSelectedBillToCompany = useBookingStore.use.setSelectedBillToCompany()
  const setSelectedBookingTypeCode = useBookingStore.use.setSelectedBookingTypeCode()

  const [getBookingType] = useLazyQuery(VIEW_BOOKING_TYPE_GQL, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.bookingType) {
        setFields?.(data.bookingType.dynamicFields)
        if (data.bookingType.status === BookingTypeStatus.Deleted) {
          form.setFieldsValue({ type: null })
        } else {
          setQueriedBookingType(data.bookingType)
          setDynamicFields(data.bookingType.dynamicFields)
        }
      }
    }
  })
  useEffect(() => {
    const currentBooking = form.getFieldsValue()
    if (currentBooking.type !== queriedBookingType?.code) {
      setInitialValues({ ...initialValues, type: currentBooking.type })
      currentBooking.type &&
        getBookingType({
          variables: {
            code: currentBooking.type
          }
        })
    }

    if (currentBooking.billToUuid) setSelectedBillToCompany(currentBooking.billToUuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  useEffect(() => {
    setSelectedBookingTypeCode(form.getFieldValue('type'))
  }, [form, setSelectedBookingTypeCode])

  const companySelectHandleChange = company => {
    setSelectedBillToCompany(company)
    mode !== FormMode.Edit && form.setFieldsValue({ type: undefined })
  }

  return (
    <StyledDiv>
      <Row>
        <Col span={12}>
          <Row>
            <Form.Item label="Billing Customer" {...formItemLayout}>
              {getFieldDecorator('billToUuid', {
                initialValue: initialValues.billTo?.uuid,
                rules: [{ required: true, message: 'Billing customer is required.' }]
              })(
                <CompanySelect
                  formId="billing-customer-selector"
                  quickCreate
                  types={['billing']}
                  accountTypes={['debtor']}
                  portalCompanyQueryType="book"
                  onChange={companySelectHandleChange}
                />
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Booking Type" required {...formItemLayout}>
              {getFieldDecorator('type', {
                initialValue: initialValues?.type,
                rules: [{ required: true, message: 'Booking type is required.' }]
              })(
                <DynamicTransportSelect
                  defaultActiveFirstOption
                  queryOnMount
                  type={'bookingTypes'}
                  query={BOOKING_TYPES_QUERY}
                  searchText={'Select booking type...'}
                  queryVariables={{ byCompany: selectedBillToCompany }}
                  disabled={mode === FormMode.Edit}
                />
              )}
            </Form.Item>
          </Row>
        </Col>

        <Col span={12}>
          <Sentry.ErrorBoundary fallback={<ComponentErrorFallback />}>
            {dynamicFields?.map(field => (
              <Form.Item
                key={field.key}
                {...formItemLayout}
                label={startCase(field.key)}
                required={field.control === DynamicFieldControl.Required}
              >
                <DynamicField
                  form={form}
                  mode={mode}
                  dynamicField={field}
                  duplicateBookingObject={initialValues}
                />
              </Form.Item>
            ))}
          </Sentry.ErrorBoundary>
        </Col>
      </Row>
    </StyledDiv>
  )
}

export default withApollo(ShipperConsigneeSubForm)
