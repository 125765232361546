import { User } from '@/types/graphql'

import withAssignee from './Container'
import SingleSelect from './SingleSelect'
import { Wrapper } from './Styled'

interface AssigneeProps {
  usersQuery?: any
  handleSelect: (user: string | null) => void
  selectedUser?: User
}

const Assignee = ({ usersQuery, handleSelect, selectedUser }: AssigneeProps) => {
  const options = (usersQuery && usersQuery.users && usersQuery.users.rows) || []

  return (
    <Wrapper>
      <SingleSelect options={options} handleSelect={handleSelect} selectedUser={selectedUser} />
    </Wrapper>
  )
}

export default withAssignee(Assignee)
