import { memo, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'
import { DatePicker, Form, Input } from 'antd'
import moment from 'moment'

import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'
import { DisplayOnlyLabel } from 'App/components/Voucher/Styled'
import { usePreviousProps } from 'App/hooks/usePreviousProps'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { useUnplannedStore } from 'App/store/unplanned'
import { useVDJMStore } from 'App/store/vdjm'
import { ContactSimpleType, LegStatus, TransportSource } from 'App/types/graphql'
import type { TransportJob } from 'App/types/graphql'
import { isUuid } from 'App/utils/u'
import DriverSelector from '../../DriverSelector'
import TrailerSelector from '../../TrailerSelector'
import VehicleSelector from '../../VehicleSelector'

const COMPANY_QUERY = gql`
  query companyForLeg1($uuid: UUID!) {
    company(uuid: $uuid) {
      accountUuid
      tags
      uuid
    }
  }
`

const leftFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 18 }
  }
}

interface LegFormDetailsProps {
  form: any
  client: any
  isVDJM?: boolean
  leg: TransportJob
  setFormError: (msg: string) => void
  setFormSuccessMsg: (msg: string) => void
}

const LegFormDetails = memo((props: LegFormDetailsProps) => {
  const { client, form, leg, setFormError, setFormSuccessMsg, isVDJM } = props
  const { getFieldDecorator } = form

  const { t } = useTranslation()
  const { selectedDriver, selectedVehicle } = useUnplannedStore()
  const { selectedDriver: selectedVdjmDriver, selectedVehicle: selectedVdjmVehicle } =
    useVDJMStore()

  const selectSelectedDriver = isVDJM ? selectedVdjmDriver : selectedDriver
  const selectSelectedVehicle = isVDJM ? selectedVdjmVehicle : selectedVehicle

  const userBaseCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [transportSource, setTransportSource] = useState(
    leg?.transportSource || TransportSource.Own
  )
  const [selectedComp, setSelectedComp] = useState<any>(null)
  const [driverPreference, setDriverPreference] = useState(null)
  const [vehiclePreference, setVehiclePreference] = useState(null)
  const [trailerPreference, setTrailerPreference] = useState(null)

  const prevComp: any = usePreviousProps(selectedComp)

  const [getCompany, { data: compData }] = useLazyQuery(COMPANY_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  // Resets states when the user selects a leg
  useEffect(() => {
    setDriverPreference(null)
    setVehiclePreference(null)
    setTrailerPreference(null)
    setFormSuccessMsg('')
    setFormError('')
  }, [leg, setFormError, setFormSuccessMsg])

  // Queries company when the user selects a company
  useEffect(() => {
    const newCompUuid = form.getFieldValue('transportUuid')

    if (prevComp?.uuid === newCompUuid) return

    if (newCompUuid && isUuid(newCompUuid)) {
      getCompany({ variables: { uuid: newCompUuid } })
    }

    if (compData?.company) {
      setSelectedComp(compData?.company)
    }
  }, [compData, form, getCompany, prevComp])

  // Sets TransportSource when the selected company changes
  useEffect(() => {
    if (selectedComp?.accountUuid === userBaseCompany?.uuid) {
      setTransportSource(TransportSource.Own)
    } else if (selectedComp?.tags?.includes('isInterComp')) {
      setTransportSource(TransportSource.Intercompany)
    } else if (selectedComp?.tags?.includes('isInterBranch')) {
      setTransportSource(TransportSource.Interbranch)
    } else if (leg?.transportSource && !selectedComp) {
      setTransportSource(leg?.transportSource)
    } else {
      setTransportSource(TransportSource.Outsource)
    }
  }, [leg, selectedComp, userBaseCompany])

  const legTypeDisplay = useMemo(
    () =>
      [leg?.sequence, leg?.legStatus]
        ?.filter((elem: number | LegStatus | null | undefined) => elem)
        ?.join(' - '),
    [leg]
  )

  const isFirstTripAndFirstLeg = leg?.sequence === 1 && leg?.tripSequence === 1

  return (
    <>
      <Form.Item label={t('common.leg')} {...leftFormItemLayout}>
        {getFieldDecorator('type', {})(<DisplayOnlyLabel>{legTypeDisplay}</DisplayOnlyLabel>)}
      </Form.Item>

      <Form.Item label={t('common.transporter')} {...leftFormItemLayout}>
        {getFieldDecorator('transportUuid', {
          initialValue:
            leg?.transportUuid ||
            ((leg?.transportSource === TransportSource.Own || !leg?.transportSource) &&
              userBaseCompany?.company?.uuid) ||
            undefined,
          rules: [
            {
              required: !!(!leg?.transportSource || leg?.transportSource === TransportSource.Own),
              message: t('validation.companyTransporterIsRequired')
            }
          ]
        })(<CompanySelect types={['transporter', 'haulier']} style={{ width: '100%' }} />)}
      </Form.Item>

      <Form.Item label={t('common.source')} {...leftFormItemLayout}>
        {getFieldDecorator('transportSource', {
          initialValue: transportSource
        })(<DisplayOnlyLabel>{transportSource}</DisplayOnlyLabel>)}
      </Form.Item>

      {transportSource !== TransportSource.Outsource && (
        <>
          <Form.Item label={t('common.driver')} {...leftFormItemLayout}>
            {getFieldDecorator('driverUuid', {
              initialValue:
                leg?.driverUuid ||
                leg?.driverEntity?.uuid ||
                driverPreference?.[0] ||
                selectSelectedDriver?.uuid,
              rules: [
                {
                  required: transportSource === TransportSource.Own,
                  message: t('validation.driverIsRequired')
                }
              ]
            })(
              <DriverSelector
                // @ts-ignore
                queryOnMount={true}
                style={{ width: '100%' }}
                setVehiclePreference={setVehiclePreference}
              />
            )}
          </Form.Item>
          <Form.Item label={t('common.assistants')} {...leftFormItemLayout}>
            {getFieldDecorator('assistantUuids', {
              //@ts-ignore TODO: techincal debt we are using outdated types
              initialValue: leg?.assistantUuids || []
            })(
              <DriverSelector
                // @ts-ignore
                queryOnMount={true}
                style={{ width: '100%' }}
                mode="multiple"
              />
            )}
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.driverCode')} {...leftFormItemLayout}>
            {getFieldDecorator('driverCode', {
              initialValue: leg?.driverCode || leg?.driverEntity?.code
            })(<Input placeholder="Enter driver code..." />)}
          </Form.Item>

          <Form.Item label={t('common.driverName')} {...leftFormItemLayout}>
            {getFieldDecorator('driverName', {
              initialValue: leg?.driverName || leg?.driverEntity?.name,
              rules: [
                {
                  required: false,
                  message: t('validation.driverNameIsRequired')
                }
              ]
            })(<Input placeholder={t('common.enterDriverName')} />)}
          </Form.Item>

          <Form.Item label={t('common.driverIc')} {...leftFormItemLayout}>
            {getFieldDecorator('driverIc', {
              //@ts-ignore TODO: techincal debt we are using outdated types
              initialValue: leg?.driverIc || leg?.driverEntity?.registration
            })(<Input placeholder={t('common.enterDriverIc')} maxLength={12} />)}
          </Form.Item>

          <Form.Item label={t('common.driverPhoneNo')} {...leftFormItemLayout}>
            {getFieldDecorator('driverPhoneNo', {
              //@ts-ignore TODO: techincal debt we are using outdated types
              initialValue:
                leg?.driverPhoneNo ||
                leg?.driverEntity?.contact?.find(
                  c => c?.type === ContactSimpleType.Primary || c?.number
                )?.number
            })(<Input placeholder={t('common.enterDriverPhoneNo')} maxLength={15} />)}
          </Form.Item>

          <Form.Item label={t('common.assistants')} {...leftFormItemLayout}>
            {getFieldDecorator('assistantUuids', {
              //@ts-ignore TODO: techincal debt we are using outdated types
              initialValue: leg?.assistantUuids || []
            })(
              <DriverSelector
                // @ts-ignore
                queryOnMount={true}
                style={{ width: '100%' }}
                mode="multiple"
              />
            )}
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Outsource && (
        <Form.Item label={t('common.vehicle')} {...leftFormItemLayout}>
          {getFieldDecorator('vehicleUuid', {
            initialValue: leg?.vehicleUuid || vehiclePreference?.[0] || selectSelectedVehicle?.uuid,
            rules: [
              {
                required: transportSource === TransportSource.Own,
                message: t('validation.vehicleIsRequired')
              }
            ]
          })(
            <VehicleSelector
              // @ts-ignore
              queryOnMount={true}
              style={{ width: '100%' }}
              setDriverPreference={setDriverPreference}
              setTrailerPreference={setTrailerPreference}
            />
          )}
        </Form.Item>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.vehicleCode')} {...leftFormItemLayout}>
            {getFieldDecorator('vehicleCode', {
              initialValue: leg?.vehicleCode || leg?.vehicleEntity?.code
            })(<Input placeholder={t('common.enterVehicleCode')} />)}
          </Form.Item>

          <Form.Item label={t('common.vehicleReg')} {...leftFormItemLayout}>
            {getFieldDecorator('vehicleName', {
              initialValue: leg?.vehicleName || leg?.vehicleEntity?.registration,
              rules: [
                {
                  required: false,
                  message: t('validation.vehicleRegistrationIsRequired')
                }
              ]
            })(<Input placeholder={t('common.enterVehicleRegistration')} />)}
          </Form.Item>
        </>
      )}

      {transportSource !== TransportSource.Outsource && (
        <Form.Item label={t('common.trailer')} {...leftFormItemLayout}>
          {getFieldDecorator('trailerUuid', {
            initialValue: leg?.trailerUuid || trailerPreference?.[0]
          })(
            // @ts-ignore
            <TrailerSelector style={{ width: '100%' }} />
          )}
        </Form.Item>
      )}

      {transportSource !== TransportSource.Own && (
        <>
          <Form.Item label={t('common.trailerCode')} {...leftFormItemLayout}>
            {getFieldDecorator('trailerCode', {
              initialValue: leg?.trailerCode || leg?.trailerEntity?.code
            })(<Input placeholder={t('common.enterTrailerCode')} />)}
          </Form.Item>

          <Form.Item label={t('common.trailerReg')} {...leftFormItemLayout}>
            {getFieldDecorator('trailerName', {
              initialValue: leg?.trailerName || leg?.trailerEntity?.registration
            })(<Input placeholder={t('common.enterTrailerRegistration')} />)}
          </Form.Item>
        </>
      )}

      <Form.Item label={t('common.remarks')} {...leftFormItemLayout}>
        {getFieldDecorator('remarks', {
          initialValue: leg?.remarks
        })(<Input placeholder={t('common.enterRemarks')} />)}
      </Form.Item>

      {!leg?.planStart && (
        <Form.Item label={'Plan Start'} {...leftFormItemLayout}>
          {getFieldDecorator('planStart', {
            initialValue: isFirstTripAndFirstLeg && leg.shipperRequiredDate ? moment(leg.shipperRequiredDate) : moment(),
            rules: [{ required: !leg?.planStart, message: 'Plan Start timing is required' }]
          })(<DatePicker showTime placeholder={`${t('validation.selectDateTime')}`} />)}
        </Form.Item>
      )}
    </>
  )
})

export default LegFormDetails
