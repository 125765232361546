import { gql } from '@/types'

export const DEFAULT_COST_ITEM = gql(`
  query findFirstCostItem($input: VouchersSearchInput) {
    vouchersSearch(input: $input) {
      rows {
        voucherItems {
          costItem {
            uuid
          }
        }
      }
    }
  }
`)

export const GET_VOUCHER_ITEMS = gql(`
  query findFirstVoucherItem($input: voucherItemQueryInput) {
    voucherItems(input: $input) {
      voucher {
        uuid
      }
      voucherItemCn {
        uuid
      }
    }
  }
`)

export const BOOKING_SEARCH_QUERY = gql(`
  query bookingItemForVoucher($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
    }
  }
`)
