import type { Job } from '@/types/graphql'

import { gql } from '@apollo/client'
import { isArray } from 'lodash'

export const COMPANIES_QUERY = gql`
  query companiesMultiPointBooking1(
    $types: [CompanyType!]
    $countryAlpha3: LimitedString
    $query: String
    $limit: Int
    $offset: Int
    $statuses: [CompanyStatus!]
    $uuids: [UUID]
  ) {
    companies(
      types: $types
      countryAlpha3: $countryAlpha3
      query: $query
      limit: $limit
      offset: $offset
      statuses: $statuses
      uuids: $uuids
    ) {
      rows {
        code
        name
        uuid
        addresses {
          name
          address1
          areaCode
          zone
          uuid
        }
      }
    }
  }
`

export const formatArrayString = (value: any) => {
  return isArray(value) ? value.join(', ') : value
}

export const defaultColumns = [
  {
    key: 'expandRowButton',
    title: '',
    align: 'left',
    width: 20
  },
  {
    key: 'index',
    title: 'No.',
    align: 'left',
    dataIndex: 'no',
    width: 42,
    render: (text: string, record: Job, index: number) => {
      return (
        <>
          {index + 1} {text}
        </>
      )
    }
  },
  {
    key: 'type',
    title: 'Job Type',
    dataIndex: 'type'
  },
  {
    key: 'measurementUnit',
    title: 'Unit',
    render: (text: string, record: Job) => {
      if (!record.details?.measurementUnit) return null
      return `${record.details.measurementUnit} ${record.details.uom}`
    }
  },
  {
    key: 'transportType',
    title: 'Transport Type',
    render: (text: string, record: Job) => record.details?.transportType
  },
  {
    key: 'remarks',
    title: 'Remarks',
    align: 'right',
    dataIndex: 'remarks',
    ellipsis: true
  }
]
