import { gql } from '@apollo/client'

export const BASIC_BOOKING_SLIM_QUERY = gql`
  query basicBookingSlim1($uuid: UUID, $no: String) {
    booking(uuid: $uuid, no: $no) {
      assignees {
        uuid
        type
        user {
          name
          uuid
          # auth0UserId
          # auth0Token
          nickname
          # givenName
          familyName
          email
          picture
          # status
          # roles
        }
      }
      createdAt
      uuid
      no
      status
      stat
      lStat
      remarks
      details
      quotation {
        quotationNo
        uuid
      }
      companies {
        name
        uuid
        code
      }
      addresses {
        uuid
        name
        address1
        address2
        address3
        address4
        city
        district
        postCode
        areaCode
        zone
        countryAlpha3
      }
      places {
        placeId
        name
        country {
          name
          alpha2
          alpha3
        }
      }
      state {
        key
        value
      }
      type
      isCloseOperation
      isCloseFinance
      billTo {
        uuid
        name
        code
        debtorTerm
        creditorTerm
      }
      permissions {
        canImpLinerOverrideIncompleteDocs
        canImpLinerOverrideDoubleVoucher
        canImpLinerOverrideDoubleInvoice
      }
      commodities
      jobs {
        bookingUuid
        uuid
        no
        jobNo
        status
        remarks
        details
        trips {
          uuid
          sequence
        }
      }
      serviceTypes
      tags
      processFlow {
        type
        sections {
          name
          type
          display
          steps {
            key
            helpText
            isComplete
            isCurrentStep
            permission {
              canApprove
              canReject
            }
          }
        }
      }
      quotation {
        uuid
        quotationNo
      }
      chronologies {
        date
        type
        jobUuid
        tripUuid
      }
    }
  }
`

export const JOBS_FROM_BOOKING_SLIM_QUERY = gql`
  query jobsFromBookingSlim1($uuid: UUID!) {
    booking(uuid: $uuid) {
      uuid
      no
      jobs {
        uuid
        bookingUuid
        tripFormat
        trips {
          uuid
          type
          status
          sequence
          from {
            name
            areaCode
            zone
            uuid
            companyUuid
          }
          to {
            name
            areaCode
            zone
            uuid
            companyUuid
          }
          remarks
          references
          seal
          details
        }
        uuid
        no
        jobNo
        type
        status
        remarks
        details
        reference {
          haulage
          transport
          shipper
          consignee
          forwardingOri
          forwardingDest
        }
        impRequestOfTransportDate
        impReadyForCollectionDate
        expRequestOfTransportDate
        expReadyForCollectionDate
      }
    }
  }
`
