import { gql } from '@apollo/client'

export default gql`
  mutation addVoucherItemSlim1($input: AddVoucherItemInput!) {
    addVoucherItem(input: $input) {
      uuid
      description
      unit
      size
      quantity
      isDeleted
      currency {
        uuid
        code
        name
      }
      baseRate
      exchangeRate
      localExchangeRate
      rate
      tax {
        uuid
        name
        code
        percentage
      }
      job {
        bookingUuid
        uuid
        no
        jobNo
      }
      taxPercentage
      taxTotal
      subTotal
      total
      bookingUuid
      costItem {
        uuid
        bookingUuid
        description
        quantity
        chargeItem {
          uuid
          code
          name
          description
          sellTax {
            uuid
            name
            code
            percentage
          }
          costTax {
            uuid
            name
            code
            percentage
          }
        }
      }
      voucherCn {
        uuid
        voucherNumber
      }
      sorting
    }
  }
`
