import { Icon, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import startCase from 'lodash/startCase'

import { usePersistedBookingData } from 'App/store/bookingData'
import { documentType } from 'App/utils/labelMap'
import Container, {
  DocDescription,
  DocFileName,
  DocHeader,
  DocName,
  DocUsage,
  LeftIcon
} from './Container'
import StatusIcons from './StatusIcons'

const Document = props => {
  const { uploadedDoc, isHighlighted, documentApproval, vouchers = [], requests = [] } = props

  const { bookingData } = usePersistedBookingData()

  const isDeleted = uploadedDoc?.status === 'DELETED'
  const isVerified = uploadedDoc?.status === 'VERIFIED'

  const docPOD = bookingData?.jobs?.flatMap(job =>
    job?.trips
      ?.filter(trip => trip.uuid === uploadedDoc.tripUuid)
      .map(trip => {
        const jobNoPart = job.jobNo.slice(10, job.jobNo.length)
        return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
      })
  )

  return (
    <Container
      verified={isVerified}
      deleted={isDeleted}
      highlighted={isHighlighted}
      onClick={props.onClick}
    >
      <DocHeader>
        <Tooltip title={startCase(uploadedDoc.type)}>
          <DocName>{startCase(documentType[uploadedDoc.type] || uploadedDoc.type)}</DocName>
        </Tooltip>
        <DocUsage>
          {requests.length > 0 && (
            <Tooltip title="Attached to requests.">
              <Icon type="mail" />
            </Tooltip>
          )}
          {vouchers.length > 0 && (
            <Tooltip title="Attached to vouchers.">
              <Icon type="dollar" />
            </Tooltip>
          )}
        </DocUsage>
      </DocHeader>
      <DocDescription>
        <DocFileName>
          <LeftIcon type={isDeleted ? 'delete' : 'paper-clip'} />
          {uploadedDoc.document.name}
          <br />
          {docPOD}
        </DocFileName>
        <StatusIcons uploadedDoc={uploadedDoc} documentApproval={documentApproval || {}} />
      </DocDescription>
    </Container>
  )
}

Document.propTypes = {
  uploadedDoc: PropTypes.object,
  onClick: PropTypes.func
}

export default Document
