import { Component } from 'react'
import { withApollo } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import Document from '../../Document'
import BookingDocumentQuery from './BookingDocumentQuery'

class UploadedItem extends Component {
  handleClick = async () => {
    const { client, uploadedDoc, handleOpenViewer, documentApproval } = this.props

    respHandler('Loading document...', 'load')

    const { data } = await client.query({
      query: BookingDocumentQuery,
      variables: {
        uuid: uploadedDoc.uuid
      }
    })

    if (!data.bookingDocument) {
      respHandler('Unable to load voucher. Please try again later.', 'error')
      return
    }

    handleOpenViewer({
      ...data.bookingDocument,
      allowedApprovals: documentApproval
    })

    respHandler('', 'close')
  }

  render() {
    const {
      uploadedDoc,
      isHighlighted,
      documentApproval,
      vouchers = [],
      requests = []
    } = this.props

    return (
      <Document
        uploadedDoc={uploadedDoc}
        vouchers={vouchers}
        requests={requests}
        documentApproval={documentApproval}
        isHighlighted={isHighlighted}
        onClick={() => this.handleClick(uploadedDoc)}
      />
    )
  }
}

export default withApollo(UploadedItem)
