import { getActTime } from 'App/components/RelativeDateTime'
import { formatVehicle } from 'App/components/Transport/Components/VehicleSelector'
import { getDriverNamePhone } from 'App/components/Transport/Planning/Jobs/helpers/getTableAccessors'
import { formatLegTimings, getTrailerDetails } from 'App/components/Transport/Utils/jobHelper'
import { TransportJob } from 'App/types/graphql'

export const formatLegTableData = (legs: [TransportJob] | undefined, vehicleCodeOrReg?: string) => {
  const formattedLegs = legs?.map((leg: TransportJob) => {
    const yard: string = '-'

    const legTimings = formatLegTimings(leg)

    return {
      ...leg,
      yard,
      fromName: leg?.from?.name || '-',
      toName: leg?.to?.name || '-',
      tripStatus: leg?.tripStatus || '-',
      legStatus: leg?.legStatus || '-',
      driverDisplay: getDriverNamePhone(leg),
      vehicleDisplay: formatVehicle(leg, vehicleCodeOrReg),
      trailerDisplay: getTrailerDetails(leg),
      shipperRequiredDate: getActTime(leg?.shipperRequiredDate),
      ...legTimings
    }
  })

  return formattedLegs
}
