import { ActiveStatus, type QueryPluginsArgs } from '@/types/graphql'
import type { ApolloClient } from '@apollo/client'
import { gql } from '@/types'

import { useEffect } from 'react'
import { QueryHookOptions, useLazyQuery } from '@apollo/client'

import { logger } from '@/utils/logger'
import { defaultsDeep } from 'lodash'

export interface usePluginProps {
  variables: QueryPluginsArgs
  options: QueryHookOptions
  queryOnMount?: boolean
  client?: ApolloClient<object>
}

const IS_PLUGIN_ENABLED = gql(`
  query isPluginEnabledHook(
    $types: [PluginType!]
    $componentNames: [String]
    $componentLocation: [String]
    $snippets: [Snippet]
    $codes: [Code]
    $statuses: [ActiveStatus]
  ) {
    plugins(
    types: $types
    componentNames: $componentNames
    componentLocation: $componentLocation
    snippets: $snippets
    codes: $codes
    statuses: $statuses
    ) {
      rows {
        id
      }
    }
  }
`)

const usePlugin = ({ client, variables, options, queryOnMount }: usePluginProps) => {
  const [checkPlugin, res] = useLazyQuery(IS_PLUGIN_ENABLED, {
    client,
    fetchPolicy: 'no-cache',
    variables: defaultsDeep(variables, { statuses: [ActiveStatus.Active] }),
    ...options
  })

  // @ts-expect-error
  if (res.error && res?.error.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 403) {
    logger.warn(`usePlugin Error name='${variables.types}'`, res.error)
  }

  useEffect(() => {
    if (!queryOnMount) return

    checkPlugin()
  }, [queryOnMount, checkPlugin])

  return {
    checkPlugin,
    ...res,
    isPluginEnabled: res.data?.plugins?.rows?.length > 0
  }
}

export default usePlugin
