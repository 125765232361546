import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { Dropdown, Icon, InputNumber, Menu, Modal, Popover, Table, Typography } from 'antd'
import { compose, withState } from 'recompose'
import { bindActionCreators } from 'redux'

import BookingSelect from '@/components/Select/BookingSelect'
import CostItemSelect from '@/components/Select/BookingSelect/cost-item-select'
import JobSelect from '@/components/Select/job/job-select'
import { useVoucherStore } from '@/store/voucher'
import Link from 'App/components/Link'
import { getPrintableLink } from 'App/components/Shared/documentCreator/openPrintableInNewTab'
import VoucherPdfAsyncDownloadAction from 'App/components/Voucher/Management/VoucherPdfAsyncDownloadAction'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import * as voucherActions from 'App/states/reducers/voucher'
import useGlobalCompanyStore from 'App/store/globalCompany'
import responseHandler from 'App/utils/responseHandler'
import CustomText from '../CustomText'
import ChangeTypeAction from './ChangeTypeAction'
import Rollover from './Rollover'

const DOCUMENT_CREATOR_TEMPLATES = gql`
  query documentCreatorTemplates5($q: String, $statuses: [DocumentCreatorTemplateStatus]) {
    documentCreatorTemplates(q: $q, statuses: $statuses) {
      rows {
        uuid
        type
      }
    }
  }
`

const Selections = memo(props => {
  const {
    client,
    selectedVoucher,
    loadRequiredData,
    bookingQuery,
    handleCreateCreditNote,
    fullBookingQuery,
    updateSelectedVoucher
  } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const openSelectVoucherItemModal = useVoucherStore.use.openSelectVoucherModal()
  const setOpenSelectVoucherItemModal = useVoucherStore.use.setOpenSelectVoucherModal()

  const [keySelected, setKeySelected] = useState('')
  const [modifiedValue, setModifiedValue] = useState({})
  const [selectedVoucherItems, setSelectedVoucherItems] = useState([])

  const handleJvValueChange = (record, field, value) => {
    const recordKey = record.uuid

    setModifiedValue(prev => ({
      ...prev,
      [recordKey]: {
        ...prev[recordKey],
        [field]: value
      }
    }))
  }

  const {
    data: dcTemplates,
    loading: dcTemplatesLoading,
    error: dcTemplatesError
  } = useQuery(DOCUMENT_CREATOR_TEMPLATES, {
    client,
    variables: { q: 'INVOICE', statuses: ['activated'] }
  })

  const { hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'documentCreatorTemplate',
    permission: 'index'
  })

  const renderRollover = useCallback(() => {
    return (
      <Rollover
        bookingQuery={bookingQuery}
        selectedVoucher={selectedVoucher}
        loadRequiredData={loadRequiredData}
      />
    )
  }, [bookingQuery, selectedVoucher, loadRequiredData])

  const handleClick = useCallback(
    ({ key }) => {
      if (key === 'creditNote' || key === 'journalVoucher') {
        setKeySelected(key)
        setOpenSelectVoucherItemModal(true)
      }
    },
    [setOpenSelectVoucherItemModal]
  )

  const handleChangedVoucherType = useCallback(
    value => {
      if (!value) return

      if (fullBookingQuery) fullBookingQuery.refetch()

      updateSelectedVoucher({
        ...selectedVoucher,
        type: value
      })
    },

    [fullBookingQuery, selectedVoucher, updateSelectedVoucher]
  )

  const status = useMemo(() => selectedVoucher?.status, [selectedVoucher])

  const menu = useMemo(
    () => (
      <Menu onClick={handleClick}>
        {status !== 'NEW' && (
          <Menu.Item key="view">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view`}>
              <Icon type="eye" style={{ marginRight: '8px' }} />
              View voucher
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="viewWithCostSheet">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view?withCostSheet=true`}>
              <Icon type="eye" style={{ marginRight: '8px' }} />
              View voucher with cost sheet
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="changeVoucherType">
            <ChangeTypeAction voucher={selectedVoucher} onChanged={handleChangedVoucherType} />
          </Menu.Item>
        )}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          dcTemplates?.documentCreatorTemplates?.rows.length &&
          dcTemplates?.documentCreatorTemplates?.rows
            .filter(t =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map(t => (
              <Menu.Item key={t.type}>
                <VoucherPdfAsyncDownloadAction
                  voucher={selectedVoucher}
                  type={t.type}
                  text={`${t.type} as PDF`}
                />
              </Menu.Item>
            ))}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          dcTemplates?.documentCreatorTemplates?.rows.length &&
          dcTemplates?.documentCreatorTemplates?.rows
            .filter(t =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map(t => (
              <Menu.Item key={t.type}>
                <a
                  target="_blank"
                  href={getPrintableLink(selectedVoucher.uuid, selectedGlobalCompany.uuid, t.type)}
                  rel="noreferrer"
                >
                  <Icon type="printer" style={{ marginRight: '8px' }} />
                  {`Print as ${t.type}`}
                </a>
              </Menu.Item>
            ))}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          !dcTemplates?.documentCreatorTemplates?.rows.length && (
            <Menu.Item key="downloadAsyncNoTemplates" disabled>
              <Typography.Text strong style={{ color: 'red' }}>
                No Document Creator Templates
              </Typography.Text>
            </Menu.Item>
          )}
        {dcTemplatesLoading && <Menu.Item key="downloadAsyncLoading">Loading...</Menu.Item>}
        {dcTemplatesError && hasPermission && (
          <Menu.Item key="downloadAsyncError">Error loading templates</Menu.Item>
        )}
        {(status === 'APPROVED' || status === 'PAID') &&
          !selectedVoucher?.isCreditNote &&
          !selectedVoucher?.isJournalVoucher && (
            <Menu.Item key="creditNote">
              <Icon type="rollback" />
              Credit note
            </Menu.Item>
          )}
        {(status === 'APPROVED' || status === 'PAID') &&
          !selectedVoucher?.isCreditNote &&
          !selectedVoucher?.isJournalVoucher && (
            <Menu.Item key="journalVoucher">
              <Icon type="dollar" />
              Journal Voucher
            </Menu.Item>
          )}
        {status !== 'NEW' && (
          <Menu.Item key="viewIntegrationLogs">
            <Link
              target="_blank"
              to={`/manage/integration-logs?voucherUuids=${selectedVoucher?.uuid}`}
            >
              <Icon type="database" style={{ marginRight: '8px' }} />
              Integration logs
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logs">
          <Link
            target="_blank"
            to={`/manage/logs/voucher/?models=voucher&voucherUuid=${selectedVoucher?.uuid}`}
          >
            <Icon type="database" style={{ marginRight: '8px' }} />
            Voucher logs
          </Link>
        </Menu.Item>
      </Menu>
    ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status, selectedVoucher, hasPermission, dcTemplates, dcTemplatesLoading, dcTemplatesError]
  )

  useEffect(() => {
    if (openSelectVoucherItemModal && selectedVoucher?.voucherItems) {
      setSelectedVoucherItems(selectedVoucher.voucherItems)
    }
  }, [openSelectVoucherItemModal, selectedVoucher?.voucherItems])

  const table = () => {
    const columns = [
      // TODO: add booking no to display here
      // {
      //   title: 'Booking',
      //   key: 'booking',
      // },
      {
        title: 'Job',
        dataIndex: 'job',
        key: 'job',
        render: (_, record) => <span>{record?.job?.jobNo}</span>
      },
      {
        title: 'Cost Item',
        key: 'costItem',
        render: (_, record) => {
          const currentRecord = modifiedValue[record.uuid] || {}
          const content = (
            <div className="w-full space-y-4 min-w-96">
              <div className="flex flex-col space-y-2">
                <span>Booking</span>
                <BookingSelect
                  allowClear
                  value={currentRecord?.bookingUuid}
                  onChange={val => handleJvValueChange(record, 'bookingUuid', val)}
                  style={{ width: '100%' }}
                />
              </div>
              {currentRecord?.bookingUuid && (
                <div className="flex flex-col space-y-2">
                  <span>Job</span>
                  <JobSelect
                    value={currentRecord?.jobUuid}
                    onChange={val => handleJvValueChange(record, 'jobUuid', val)}
                    bookingUuid={currentRecord.bookingUuid}
                  />
                </div>
              )}
              {currentRecord?.bookingUuid && currentRecord?.jobUuid && (
                <div className="flex flex-col space-y-2">
                  <span>Cost Item</span>
                  <CostItemSelect
                    className="w-full"
                    bookingUuid={currentRecord.bookingUuid}
                    value={currentRecord?.costItemUuid}
                    onChange={val => handleJvValueChange(record, 'costItemUuid', val)}
                  />
                </div>
              )}
            </div>
          )

          return (
            <Popover content={content} title="Cost Item" placement="bottom" trigger="click">
              <div className="space-x-2 cursor-pointer">
                <span>{record.costItem.chargeItem.code}</span>
                <Icon type="edit" />
              </div>
            </Popover>
          )
        }
      },
      {
        title: 'JV Cost Item',
        key: 'jvCostItem',
        render: (_, record) => (
          <span>
            {record.costItem.chargeItem.code} - {record.costItem.chargeItem.name}
          </span>
        )
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
        render: (text, record) => (
          <InputNumber
            defaultValue={text}
            value={modifiedValue[record.uuid]?.quantity || text}
            onChange={value => handleJvValueChange(record, 'quantity', value)}
            min={0}
          />
        )
      },
      {
        title: 'Base Rate',
        dataIndex: 'baseRate',
        key: 'baseRate',
        render: (text, record) => (
          <div className="space-x-2">
            <CustomText muted noPadding text={record.currency.code} />
            <InputNumber
              defaultValue={text}
              value={modifiedValue[record.uuid]?.baseRate || text}
              onChange={value => handleJvValueChange(record, 'baseRate', value)}
            />
          </div>
        )
      }
    ]

    const rowSelection = {
      selectedRowKeys: selectedVoucherItems.map(item => item.uuid),
      onChange: (_, selectedRows) => setSelectedVoucherItems(selectedRows),
      getCheckboxProps: record => ({
        uuid: record.uuid
      })
    }

    return (
      <Table
        dataSource={selectedVoucher?.voucherItems}
        rowSelection={rowSelection}
        rowKey={record => record.uuid}
        columns={columns}
        pagination={false}
      />
    )
  }

  const handleOnOk = () => {
    let modifiedItems = { ...selectedVoucherItems }

    if (selectedVoucherItems.length === 0)
      return responseHandler('At least one voucher item needs to be selected', 'error')

    if (Object.keys(modifiedValue).length > 0) {
      modifiedItems = selectedVoucherItems.map(item => {
        const modified = modifiedValue[item.uuid]
        if (modified) {
          return {
            ...item,
            ...modified,
            job: { uuid: modified.jobUuid },
            costItem: { uuid: modified.costItemUuid }
          }
        }
        return item
      })
    }

    handleCreateCreditNote(keySelected, modifiedItems)
  }

  return (
    <>
      {renderRollover()}
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon type="ellipsis" className="cursor-pointer" />
      </Dropdown>
      <Modal
        title="JV Items to Create"
        width={'80%'}
        destroyOnClose
        closable={false}
        visible={openSelectVoucherItemModal}
        onOk={handleOnOk}
        onCancel={() => setOpenSelectVoucherItemModal(false)}
      >
        {table()}
      </Modal>
    </>
  )
})

export default compose(
  withApollo,
  withState('showSelections', 'toggleSelections', false),
  connect(null, dispatch => ({ dispatch, ...bindActionCreators({ ...voucherActions }, dispatch) }))
)(Selections)
