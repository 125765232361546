import { useState } from 'react'
import { compose, graphql, Mutation } from 'react-apollo'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Affix, Col, Collapse, Empty, Icon, Typography } from 'antd'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

import DocumentViewer from 'App/components/DocumentViewer'
import VoucherModal from 'App/components/Voucher/ModalView'
import { BASIC_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import BookingContext from 'App/contexts/booking'
import { MAKE_BOOKING_MUTATION } from 'App/graphql/booking'
import withRequisition from 'App/hocs/requisition'
import { generateDuplicateBookingRequest } from 'App/pages/NewBooking/Utils'
import { useBookingStore } from 'App/store/booking'
import handleResponse from 'App/utils/responseHandler'
import styles from './BookingForm.module.css'
import { getBkQueryVariables } from './bookingHelper'
import { EmptyBooking } from './Container'
import Events from './Events'
import OverviewSection from './OverviewSection'
import Status from './Status'
import { StyledContent } from './Styled'
import Workflows from './Workflows'

// TODO: major refactor needed to use hooks instead of hoc - Harith
const BookingForm = props => {
  const { Panel } = Collapse
  const isBookingCollapse = useBookingStore.use.isBookingCollapse()
  const setIsBookingCollapse = useBookingStore.use.setIsBookingCollapse()
  const showVoucher = useSelector(state => state?.voucher?.showVoucher)
  const showDocumentViewer = useSelector(state => state?.bookingDocument?.showDocumentViewer)

  const { bookingQuery, history } = props
  const { booking } = bookingQuery

  const [refreshCount, setRefreshCount] = useState(0)

  if (isEmpty(booking)) {
    if (bookingQuery.loading) {
      return (
        <EmptyBooking>
          <Icon type="loading" />
        </EmptyBooking>
      )
    }

    if (bookingQuery.error) {
      const { error } = bookingQuery
      if (error.graphQLErrors?.[0]?.message) {
        handleResponse(error.graphQLErrors?.[0]?.message, 'error')
      }
    }

    return <Empty style={{ marginTop: '20px' }} />
  }

  const refresh = () => {
    setRefreshCount(refreshCount + 1)
    bookingQuery.refetch()
  }

  const handleDuplicate = makeBooking => {
    if (booking) {
      const newBookingRequest = generateDuplicateBookingRequest(booking)
      makeBooking({
        variables: {
          input: newBookingRequest
        }
      })
    }
  }

  const handleDuplicated = result => {
    const newBooking = result.makeBooking?.[0]
    handleResponse('Duplicated successfully.', 'success')
    history.push(`/bookings/${newBooking.uuid}`)
  }

  const createdAtDate = moment(bookingQuery?.booking?.createdAt).format('DD/MM/YYYY')
  const createdAtTime = moment(bookingQuery?.booking?.createdAt).format('hh:mm A')

  return (
    <BookingContext.Provider value={props.bookingQuery}>
      <div id="bookingOverview" />
      <StyledContent>
        <div className={styles.page}>
          <Col
            xs={23}
            md={23}
            lg={!isBookingCollapse ? 20 : 23}
            className={!isBookingCollapse ? styles.isToggleCollapse : styles.isToggleExpand}
          >
            <Mutation mutation={MAKE_BOOKING_MUTATION} onCompleted={handleDuplicated}>
              {(makeBooking, { loading }) => (
                <OverviewSection
                  booking={booking}
                  duplicating={loading}
                  handleDuplicate={() => handleDuplicate(makeBooking)}
                />
              )}
            </Mutation>
            <Workflows
              bookingUuid={booking?.uuid}
              requisitionActions={props.requisitionActions}
              refreshCount={refreshCount}
            />
          </Col>

          <Col xs={23} md={23} lg={!isBookingCollapse ? 4 : 1}>
            <Affix offsetTop={66}>
              <div>
                <div
                  style={{
                    display: 'flex',
                    justifyItems: 'end',
                    flexDirection: 'column',
                    alignItems: 'end',
                    marginRight: 5,
                    margin: '10px 30px 0 0'
                  }}
                >
                  <div
                    onClick={() => setIsBookingCollapse(!isBookingCollapse)}
                    className="cursor-pointer"
                  >
                    {!isBookingCollapse ? (
                      <MenuUnfoldOutlined style={{ fontSize: '15px' }} />
                    ) : (
                      <MenuFoldOutlined style={{ fontSize: '15px' }} />
                    )}
                  </div>
                </div>
                {!isBookingCollapse && (
                  <Collapse
                    bordered={true}
                    defaultActiveKey={['1', '2', '3']}
                    style={{ margin: '10px 5px' }}
                  >
                    <Panel
                      key={'1'}
                      header={
                        <Typography.Text strong className={styles.collapseText}>
                          STATUS
                        </Typography.Text>
                      }
                    >
                      <Status bookingState={booking?.state} booking={booking} />
                    </Panel>
                    <Panel
                      key={'2'}
                      header={
                        <Typography.Text strong className={styles.collapseText}>
                          CREATED AT
                        </Typography.Text>
                      }
                    >
                      <Typography.Text style={{ fontSize: 12 }}>
                        {createdAtDate}
                        <br />
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: 12 }}>{createdAtTime}</Typography.Text>
                    </Panel>
                    <Panel
                      key={'3'}
                      header={
                        <Typography.Text strong className={styles.collapseText}>
                          EVENT
                        </Typography.Text>
                      }
                    >
                      <Events bookingUuid={booking?.uuid} />
                    </Panel>
                  </Collapse>
                )}
              </div>
            </Affix>
          </Col>
        </div>
        {showVoucher && <VoucherModal bookingQuery={bookingQuery} />}
        {showDocumentViewer && (
          <DocumentViewer refreshBooking={refresh} bookingQuery={bookingQuery} />
        )}
      </StyledContent>
    </BookingContext.Provider>
  )
}

const basicBookingGraphql = graphql(BASIC_BOOKING_SLIM_QUERY, {
  name: 'bookingQuery',
  options: props => ({
    variables: getBkQueryVariables(props.match?.params),
    fetchPolicy: 'cache-and-network'
  })
})

export default compose(basicBookingGraphql, withRequisition, withRouter)(BookingForm)
