import type { ApolloClient } from '@apollo/client'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import type { RouterProps } from 'react-router-dom'
import { ActiveStatus, DynamicFieldControl, PluginType, Snippet } from '@/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Button, Checkbox, Col, Form, Row, Typography } from 'antd'
import { isUndefined } from 'lodash'
import styled from 'styled-components'

import ButtonLayout from '@/components/Booking/NewBookingForm/ButtonLayout'
import ConfirmationSubForm from '@/components/Booking/NewBookingForm/ConfirmationSubForm'
import DefaultActions from '@/components/Booking/NewBookingForm/DefaultAction'
import FormSteps from '@/components/Booking/NewBookingForm/FormSteps'
import HiddenFieldsSubForm from '@/components/Booking/NewBookingForm/HiddenFieldsSubForm'
import ShipperConsigneeSubForm from '@/components/Booking/NewBookingForm/ShipperConsigneeSubForm'
import SubmitAction from '@/components/Booking/NewBookingForm/SubmitAction'
import TransportDetailsSubForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm'
import VisibilityDiv from '@/components/Booking/NewBookingForm/VisibilityDiv'
import useJobTypes from '@/hooks/useJobTypes'
import usePlugin from '@/hooks/usePlugin'
import { PluginComponentLocation, PluginComponentName } from '@/plugins/options'
import PluginLoader from '@/plugins/PluginLoader'
import { useBookingStore } from '@/store/booking'
import responseHandler from '@/utils/responseHandler'

const StyledDiv = styled.div`
  padding-bottom: 42px;
  .formSteps {
    margin-bottom: 24px;
  }
`

const getTripsErrorMessage = errors => {
  const messages: string[] = []

  errors.forEach(subArr => {
    subArr.forEach(item => {
      if (item) {
        Object.values(item).forEach((errorObj: any) => {
          if (errorObj.errors && errorObj.errors.length > 0) {
            messages.push(errorObj.errors[0].message)
          }
        })
      }
    })
  })
  return messages
}

const getErrorMessages = errors => {
  const messages: string[] = []

  const traverseErrors = obj => {
    for (const key in obj) {
      const error = obj[key]
      if (error?.errors && error?.errors?.length > 0) {
        messages.push(error.errors?.[0].message)
      } else if (typeof error === 'object') {
        traverseErrors(error)
      }
    }
  }
  traverseErrors(errors)
  return messages
}

const CreateBkErr = ({ jobErr }) => {
  return <Typography.Text style={{ color: 'red' }}>{jobErr}</Typography.Text>
}

type NewBookingTransportFormProps = RouterProps & {
  form: WrappedFormUtils
  client: ApolloClient<object>
}

const NewBookingTransportForm = memo((props: NewBookingTransportFormProps) => {
  const { history, form, client } = props
  const { jobTypes, requestJobTypes } = useJobTypes(client)

  const [step, setStep] = useState(0)
  const [jobErr, setJobErr] = useState('')
  const [showJobErr, setShowJobErr] = useState(false)
  const [isOverrideBlockDuplicate, setIsOverrideBlockDuplicate] = useState(false)
  const [makeBookingResponse, setMakeBookingResponse] = useState(null)

  const resetSelectedBillToCompany = useBookingStore.use.resetSelectedBillToCompany()
  const pluginSplitJobsEnabled = useBookingStore.use.pluginSplitJobsEnabled()
  const setPluginSplitJobsEnabled = useBookingStore.use.setPluginSplitJobsEnabled()

  usePlugin({
    client,
    queryOnMount: true,
    options: {
      onCompleted: data => {
        setPluginSplitJobsEnabled({
          ...pluginSplitJobsEnabled,
          asset: !!data?.plugins?.rows.length
        })
      }
    },
    variables: {
      types: [PluginType.ComponentName],
      componentNames: [PluginComponentName.BookingPostSubmissionSummary]
    }
  })

  usePlugin({
    client,
    queryOnMount: true,
    options: {
      onCompleted: data => {
        setPluginSplitJobsEnabled({ ...pluginSplitJobsEnabled, api: !!data?.plugins?.rows.length })
      }
    },
    variables: {
      types: [PluginType.Snippet],
      snippets: [Snippet.BookingCreatePreSplitOneJobPerBooking]
    }
  })

  const onBack = useCallback(() => {
    if (step) {
      setStep(step - 1)
    }
  }, [step])

  useEffect(() => {
    requestJobTypes()

    return () => resetSelectedBillToCompany()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetSelectedBillToCompany])

  const checkRequiredFields = () => {
    if (!jobTypes.length) {
      requestJobTypes()
    }

    if (step === 0) {
      form.validateFields(err => {
        if (err) {
          setShowJobErr(true)
          const errorMessages = getErrorMessages(err).join(', ')
          setJobErr(errorMessages)
        } else {
          setShowJobErr(false)
          setStep(step + 1)
        }
      })
    }

    form.getFieldValue('jobs').forEach(async (job, jobIndex) => {
      if (step === 1) {
        const checkRequiredFields = jobTypes?.find(type => type?.code === job?.type)

        if (!checkRequiredFields) {
          setShowJobErr(true)
          return setJobErr(`Job #${jobIndex + 1}:Type is required`)
        }

        const defaultRequiredFields = ['type']
        const fieldsNeedToValidate = ['requiredFields', 'dynamicFields', 'tripDynamicFields']

        const jobInputFields = fieldsNeedToValidate
          .flatMap(field =>
            checkRequiredFields[field]
              .filter(f => f.control === 'REQUIRED')
              .map(f => f.key || f.name)
          )
          .concat(defaultRequiredFields)

        // get job details error messages
        const validateDetailJob = Object.entries(job.details || {}).map(([key, value]) => {
          if (jobInputFields.includes(key) && !value) {
            return { [key]: { errors: [{ message: `Job #${jobIndex + 1}:${key} is required` }] } }
          }
          return null
        })

        const tripsInputFields = ['fromCompanyUuid', 'fromUuid', 'toCompanyUuid', 'toUuid']
        const tripDynamicFields = checkRequiredFields?.tripDynamicFields
          ?.filter(f => f?.control === DynamicFieldControl.Required)
          .map(f => f?.key)

        // get job trips errror messages
        const validateTrips = job.trips.map((trip, tripIndex) => {
          const missingFields = tripsInputFields.filter(field => !Object.keys(trip).includes(field))
          const missingFieldsErrors = missingFields.map(field => ({
            [field as string]: {
              errors: [{ message: `Trip #${tripIndex + 1}: ${field} is required` }]
            }
          }))

          const missingTripDynamicFields = tripDynamicFields?.filter(
            field => field && !trip.details[field]
          )
          const missingTripDynamicFieldsErrors = missingTripDynamicFields?.map(field => ({
            [field as string]: {
              errors: [{ message: `Trip #${tripIndex + 1}: ${field} is required` }]
            }
          }))

          const fieldErrors = Object.entries(trip).map(([key, value]) => {
            if (tripsInputFields.includes(key) && !value) {
              return {
                [key]: { errors: [{ message: `Trip #${tripIndex + 1}: ${key} is required` }] }
              }
            }
            return null
          })

          return [...missingFieldsErrors, ...fieldErrors, ...(missingTripDynamicFieldsErrors || [])]
        })

        const detailErrorMessage = getErrorMessages(validateDetailJob)
        const tripsErrorMessage = getTripsErrorMessage(validateTrips)

        if (detailErrorMessage.length || tripsErrorMessage.length) {
          setShowJobErr(true)
          const errorMessages = [...detailErrorMessage, ...tripsErrorMessage].join(', ')
          return setJobErr(errorMessages)
        }
        setShowJobErr(false)
        setStep(step + 1)
      }
    })
  }

  const StepButtons = () => (
    <ButtonLayout>
      {showJobErr && <CreateBkErr jobErr={jobErr} />}

      <DefaultActions form={form} />

      {step !== 0 && (
        <Button icon="left" onClick={onBack}>
          Back
        </Button>
      )}

      <Button
        id="create-booking-stepper-button"
        icon="right"
        type="primary"
        onClick={checkRequiredFields}
      >
        Next
      </Button>
    </ButtonLayout>
  )

  const handleOverrideBlockDuplicate = e => {
    setIsOverrideBlockDuplicate(e.target.checked)
  }

  useEffect(() => {
    if (
      Object.values(pluginSplitJobsEnabled).filter(isUndefined).length ||
      (pluginSplitJobsEnabled.asset && pluginSplitJobsEnabled.api) ||
      (!pluginSplitJobsEnabled.asset && !pluginSplitJobsEnabled.api)
    ) {
      return
    }

    if (pluginSplitJobsEnabled.asset && !pluginSplitJobsEnabled.api) {
      return responseHandler(
        `The plugin type "${PluginType.Snippet}" for "${Snippet.BookingCreatePreSplitOneJobPerBooking}" is missing. The plugin will not work.`,
        'warning'
      )
    } else if (!pluginSplitJobsEnabled.asset && pluginSplitJobsEnabled.api) {
      return responseHandler(
        `The plugin type "${PluginType.ComponentName}" for "${PluginComponentName.BookingPostSubmissionSummary}" is missing. This will result in a poor user experience.`,
        'warning'
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginSplitJobsEnabled.api, pluginSplitJobsEnabled.asset])

  const postSubmitAction = useCallback(
    res => {
      const data = res?.data?.makeBooking || res?.makeBooking

      if (pluginSplitJobsEnabled.api && !pluginSplitJobsEnabled.asset) {
        history.push(`/bookings`)
        return
      }

      if (
        Array.isArray(data) &&
        data.length === 1 &&
        data[0].uuid &&
        !pluginSplitJobsEnabled.asset
      ) {
        history.push(`/bookings/${data[0].uuid}`)
        return
      }

      setMakeBookingResponse(data)
      setStep(prev => prev + 1)
    },
    [pluginSplitJobsEnabled.api, pluginSplitJobsEnabled.asset, history]
  )

  return (
    <StyledDiv>
      <Row className="formSteps">
        <FormSteps currentStep={step} />
      </Row>

      <Form>
        <HiddenFieldsSubForm form={form} />
        {/* @ts-ignore */}
        <VisibilityDiv visible={step === 0}>
          <ShipperConsigneeSubForm {...props} />
          <StepButtons />
        </VisibilityDiv>

        {/* @ts-ignore */}
        <VisibilityDiv visible={step === 1}>
          <TransportDetailsSubForm {...props} />
          <StepButtons />
        </VisibilityDiv>

        {/* @ts-ignore */}
        <VisibilityDiv visible={step === 2}>
          <ConfirmationSubForm form={form} step={step} />
          <Row type="flex" style={{ marginTop: 80 }} justify="end" align="middle" gutter={1}>
            <Col
              key={'1'}
              style={{ gap: 5, cursor: 'pointer' }}
              onClick={() => setIsOverrideBlockDuplicate(prev => !prev)}
            >
              Override Duplicate Booking{' '}
              <Checkbox
                checked={isOverrideBlockDuplicate}
                value={isOverrideBlockDuplicate}
                onChange={handleOverrideBlockDuplicate}
              />
            </Col>
          </Row>
          <ButtonLayout>
            <Button onClick={onBack}>Back</Button>
            <SubmitAction
              form={form}
              postSubmitAction={postSubmitAction}
              isOverrideBlockDuplicate={isOverrideBlockDuplicate}
            />
          </ButtonLayout>
        </VisibilityDiv>

        {pluginSplitJobsEnabled.asset && ( //@ts-ignore
          <VisibilityDiv visible={step === 3}>
            <PluginLoader
              componentLocation={[PluginComponentLocation.NewBookingForm]}
              componentProps={{ bookings: makeBookingResponse }}
            />
          </VisibilityDiv>
        )}
      </Form>
    </StyledDiv>
  )
})

// @ts-ignore
export default withApollo(withRouter(Form.create()(NewBookingTransportForm)))
