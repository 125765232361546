import type { DynamicField } from '@/types/graphql'
import type { ApolloClient } from '@apollo/client'
import { DynamicFieldQuery, DynamicFieldType } from '@/types/graphql'

import { useState } from 'react'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { Descriptions, Row } from 'antd'
import { get, isArray, startCase } from 'lodash'

import { rootKeys } from '@/components/Booking/DynamicField/helper'
import { VIEW_BOOKING_TYPE_GQL } from '@/components/Booking/General'
import Company from '@/components/Booking/NewBookingForm/ConfirmationSubForm/CompanyInfo/Company'
import JobsTable from '@/components/Booking/NewBookingForm/ConfirmationSubForm/JobsTable'
import Place from '@/components/Booking/NewBookingForm/ConfirmationSubForm/Place'
import Port from '@/components/Booking/NewBookingForm/ConfirmationSubForm/Port'
import UploadDocument from '../../DynamicField/UploadDocument'

function formatArrayString(value: any, field: DynamicField, client: ApolloClient<any>) {
  let v = value

  if (isArray(v) || (field.multiple && field.type === DynamicFieldType.Selector)) {
    v = value.join(', ')
  }

  if (field.type === DynamicFieldType.Date) {
    v = value && value.format('LLL')
  }

  if (field.query === DynamicFieldQuery.Company) {
    v = value && <Company companyUuid={value.uuid || value} />
  }

  if (field.query === DynamicFieldQuery.Place) {
    v = value && <Place placeId={value} client={client} />
  }

  if (field.query === DynamicFieldQuery.Port) {
    v = value && <Port uuid={value} client={client} />
  }

  if (field.type === DynamicFieldType.Document) {
    v = value && <UploadDocument data={value} isUploadable={false} />
  }

  return v || ''
}

const getDynamicFieldKey = (key: string) => {
  return rootKeys.includes(key) ? key : `details.${key}`
}

const ConfirmationSubForm = ({ form, step, client }: any) => {
  const { getFieldValue } = form

  const [dynamicBookingDetails, setDynamicBookingDetails] = useState<DynamicField[]>([])

  useQuery(VIEW_BOOKING_TYPE_GQL, {
    client,
    fetchPolicy: 'cache-first',
    variables: { code: getFieldValue('type') },
    onCompleted: data => {
      const fields = get(data, 'bookingType.dynamicFields', [])

      const parsedFields = fields.map(({ key, type, ...res }: any) => {
        return {
          ...res,
          type,
          label: startCase(key),
          key: getDynamicFieldKey(key)
        }
      })
      setDynamicBookingDetails(parsedFields)
    }
  })

  return (
    <>
      <Row style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
        <Descriptions title="Booking Details" bordered>
          <Descriptions.Item label="Billing Customer" span={3}>
            <Company companyUuid={getFieldValue('billToUuid')} />
          </Descriptions.Item>
          <Descriptions.Item label="Billing Type" span={3}>
            {getFieldValue('type')}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions bordered>
          {dynamicBookingDetails.map(field => (
            <Descriptions.Item
              key={field.key}
              span={dynamicBookingDetails.length > 9 ? 1 : 2}
              label={startCase(field.key?.split('.')?.[1] || field.key || '')}
            >
              {formatArrayString(getFieldValue(field.key), field, client) || '-'}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </Row>

      <Descriptions title="Job Details">{}</Descriptions>

      <JobsTable form={form} step={step} />
    </>
  )
}

export default withApollo(ConfirmationSubForm)
