import { graphql } from 'react-apollo'
import { Button, Popconfirm } from 'antd'
import { compose, withHandlers, withState } from 'recompose'

import bookingSchema from 'App/containers/booking/schema'
import { DELETE_COST_ITEM } from 'App/graphql/costItem'
import respHandler from 'App/utils/responseHandler'

const withGqlDeleteCostItem = graphql(DELETE_COST_ITEM, {
  props: ({ mutate }) => ({
    deleteCostItem: (uuid, bookingUuid) =>
      mutate({
        variables: {
          uuid
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ],
        awaitRefetchQueries: true
      })
  })
})

const loadingState = withState('loading', 'setLoading', false)

const handlers = withHandlers({
  handleDelete: props => async () => {
    const { deleteCostItem, costItem, setLoading, onCostItemDeleted } = props

    setLoading(true)
    respHandler('Deleting cost item, hang on...', 'load')

    try {
      await deleteCostItem(costItem.uuid, costItem.bookingUuid)

      setLoading(false)
      respHandler('Deleted successfully.', 'success')

      if (onCostItemDeleted) {
        onCostItemDeleted(costItem)
      }
    } catch (error) {
      setLoading(false)
      respHandler(error, 'error')
    }
  }
})

const enhance = compose(withGqlDeleteCostItem, loadingState, handlers)

const DeleteCostItemAction = props => {
  const { handleDelete, loading, costItem } = props

  return (
    <Popconfirm
      title="Are you sure?"
      okText="Yes"
      okType="danger"
      cancelText="No"
      onConfirm={handleDelete}
    >
      <Button type="danger" loading={loading} disabled={costItem?.isDeleted}>
        {costItem?.isDeleted ? 'Deleted' : 'Delete'}
      </Button>
    </Popconfirm>
  )
}

export default enhance(DeleteCostItemAction)
