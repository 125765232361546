import { Component } from 'react'
import { graphql, Mutation } from 'react-apollo'
import { Form, Icon, Modal, notification, Select } from 'antd'
import { debounce } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { compose, withState, withStateHandlers } from 'recompose'
import uuidv4 from 'uuid/v4'

import PageHeader from '@/components/Manage/PageHeader'
import { HeaderItem, ModalHeader } from '@/components/Manage/Styled'
import AddressFormModal from '@/components/Shared/AddressFormModal'
import { ADDRESS_CREATE } from '@/graphql/address'
import { formatCompanyAddress } from '@/utils/company'
import handleResponse from '@/utils/responseHandler'
import ADDRESSES_QUERY from './schema'
import { BoldSecondaryText, QuickAddDiv, SecondaryText, Separator } from './Styled'

const Option = Select.Option

const handlers = withStateHandlers(
  {},
  {
    handleChange: (state, props) => value => {
      props.onChange(value)
    }
  }
)

const enhance = compose(
  Form.create(),
  handlers,
  withState('isAddressFormOpen', 'toggleAddressFormOpen', false),
  graphql(ADDRESSES_QUERY, {
    skip: ({ companyUuid }) => isEmpty(companyUuid),
    options: ({ q, type, companyUuid }) => ({
      variables: {
        q,
        type,
        companyUuid
      },
      fetchPolicy: 'cache-first'
    })
  })
)

class AddressSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uuid: uuidv4()
    }
  }

  handleSelect = value => this.props.handleChange(value)

  handleSearch = debounce(value => {
    this.props.data?.refetch({
      q: value,
      type: this.props.type,
      companyUuid: this.props.companyUuid
    })
  }, 300)

  handleSubmit = (e, addAddress, companyId) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const input = {
            uuid: this.state.uuid,
            ...formatCompanyAddress(values),
            companyUuid: companyId
          }

          await addAddress({
            variables: {
              input
            }
          })

          notification.success({
            message: 'Submit Result',
            description: 'Address has been created successfully, please refresh'
          })
        } catch (error) {
          handleResponse(error, 'Please ensure a company is selected')
        }
      }
    })
  }

  handleFormModalVisibility = () => {
    const { isAddressFormOpen, toggleAddressFormOpen } = this.props

    toggleAddressFormOpen(!isAddressFormOpen)
  }

  componentDidUpdate() {
    const { value, data, onChange } = this.props

    const addresses = data?.addresses?.rows || []

    if (addresses?.length === 1 && (!value || !addresses.find(a => a.uuid === value))) {
      onChange(addresses[0].uuid)
    }
  }

  handleCompleted = () => {
    this.props.data.refetch()
    this.handleFormModalVisibility()
    this.props.form.resetFields()
    this.props.onChange(this.state.uuid)
  }

  render() {
    const {
      data,
      form,
      style,
      value,
      disabled = false,
      quickAdd = false,
      companyId = null
    } = this.props

    const addresses = data?.addresses?.rows || []
    const selectedAddress = addresses.find(a => a.uuid === value)
    const bindingValue = selectedAddress?.uuid || undefined

    const renderQuickAdd = () => {
      const { isAddressFormOpen } = this.props

      return (
        <QuickAddDiv>
          <span onClick={this.handleFormModalVisibility}>
            <Icon type="plus-circle" />
            Add Address
          </span>
          <Modal
            footer={null}
            width={'80%'}
            style={{ top: 20 }}
            visible={isAddressFormOpen}
            onCancel={this.handleFormModalVisibility}
            title={
              <ModalHeader>
                <HeaderItem span={20}>
                  <PageHeader type="none" title="Address Details" />
                </HeaderItem>
              </ModalHeader>
            }
          >
            <Mutation mutation={ADDRESS_CREATE} onCompleted={this.handleCompleted}>
              {addAddress => (
                <AddressFormModal
                  form={form}
                  mode="create"
                  submitText="Submit"
                  companyId={companyId}
                  onCustomSubmit={e => this.handleSubmit(e, addAddress, companyId)}
                />
              )}
            </Mutation>
          </Modal>
        </QuickAddDiv>
      )
    }

    return (
      <>
        <Select
          showSearch
          filterOption
          style={style}
          disabled={disabled}
          value={bindingValue}
          onChange={this.handleSelect}
          onSearch={this.handleSearch}
          optionLabelProp="name"
          optionFilterProp="children"
          notFoundContent="No items found."
          placeholder="Select an address..."
        >
          {addresses.map(address => {
            return (
              <Option key={address.uuid} value={address.uuid} name={address.name}>
                {address.name}
                <Separator />
                <SecondaryText>{address.address1 || '-'}</SecondaryText>
                {address.address2 && <SecondaryText>{address.address2}</SecondaryText>}
                {address.address3 && <SecondaryText>{address.address3}</SecondaryText>}
                {address.address4 && <SecondaryText>{address.address4}</SecondaryText>}
                <div style={{ display: 'flex' }}>
                  <SecondaryText>{`${address.postCode || ''},`}&nbsp;</SecondaryText>
                  {address.areaCode && (
                    <BoldSecondaryText>{address.areaCode}&nbsp;</BoldSecondaryText>
                  )}
                  <SecondaryText>{`[${address.zone}]`}</SecondaryText>
                </div>
              </Option>
            )
          })}
        </Select>
        {quickAdd && !disabled ? renderQuickAdd() : null}
      </>
    )
  }
}

export default enhance(AddressSelect)
