import type { ApolloClient } from '@apollo/client'
import { ActiveStatus } from '@/types/graphql'

import { PropsWithChildren } from 'react'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { Typography } from 'antd'

import DynamicComponentLoader from '@/components/dynamic-component-loader'
import Spinner from '@/components/Spinner'
import { GET_PLUGIN_LIST_TEST } from '@/pages/manage/plugins/schema'
import { PluginComponentLocation } from '@/plugins/options'

const { Text } = Typography

type PluginLoaderProps = {
  componentLocation: PluginComponentLocation[]
  componentProps: Record<string, any>
  client?: ApolloClient<object>
}

const PluginLoader = (props: PropsWithChildren<PluginLoaderProps>) => {
  const { client, componentLocation, componentProps } = props
  const { data, loading, error } = useQuery(GET_PLUGIN_LIST_TEST, {
    client,
    variables: {
      componentLocation,
      statuses: [ActiveStatus.Active]
    },
    skip: !componentLocation?.length
  })

  if (loading) return <Spinner />
  if (error) {
    return <Text strong type="warning">{`Error while loading plugins. ${error?.message}`}</Text>
  }

  return (
    <>
      {data?.plugins?.rows?.map(plugin => {
        if (!plugin?.componentName) {
          return null
        }

        return (
          <DynamicComponentLoader
            client={client}
            loading={loading}
            key={plugin.id}
            componentName={plugin.componentName}
            {...componentProps}
          />
        )
      })}
    </>
  )
}

export default withApollo(PluginLoader)
