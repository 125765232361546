import { connect } from 'react-redux'
import { Table } from 'antd'
import findIndex from 'lodash/findIndex'
import { withHandlers } from 'recompose'
import { bindActionCreators, compose } from 'redux'

import { Brief } from 'App/components/Workflow/Styled'
import * as voucherActions from 'App/states/reducers/voucher'
import { NoItemsFound, SectionTitle, TableWrapper } from '../Styled'
import SummaryView from './SummaryView'

const enhance = compose(
  connect(
    state => ({
      showDeletedVoucherItems: state.voucher.showDeletedVoucherItems
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        // @ts-ignore
        {
          ...voucherActions
        },
        dispatch
      )
    })
  ),
  withHandlers({
    toggleVoucherItemsView: (props: any) => async e => {
      if (e.target && e.target.value) {
        const { updateVoucherItemsView } = props

        updateVoucherItemsView(e.target.value)
      }
    },
    toggleVoucherItemsDeletedView: props => () => {
      const { showDeletedVoucherItems, updateVoucherItemsDeletedView } = props

      updateVoucherItemsDeletedView(!showDeletedVoucherItems)
    }
  })
)

const TableView = ({
  columns,
  dataSource,
  selectedVoucher,
  showDeletedVoucherItems,
  toggleVoucherItemsDeletedView
}) => {
  const renderShowHideButton = () => {
    const { voucherItems = [] } = selectedVoucher
    const hasDeletedVoucherItem = findIndex(voucherItems, (vi: any) => vi.isDeleted) >= 0

    if (hasDeletedVoucherItem) {
      return (
        // @ts-ignore
        <Brief onClick={toggleVoucherItemsDeletedView}>
          {showDeletedVoucherItems ? 'Hide deleted' : 'Show deleted'}
        </Brief>
      )
    }
    return null
  }

  const voucherItems = selectedVoucher?.voucherItems || []

  if (!voucherItems.length) {
    return <NoItemsFound>No voucher items selected.</NoItemsFound>
  }

  return (
    <>
      <SectionTitle borderless>
        Selected Items
        {renderShowHideButton()}
      </SectionTitle>

      <TableWrapper>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          locale={{ emptyText: 'No items selected.' }}
        />

        <SummaryView selectedVoucher={selectedVoucher} />
      </TableWrapper>
    </>
  )
}

export default enhance(TableView)
