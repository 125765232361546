import UploadButton from './UploadButton'

type UploaderProps = {
  type: string
  label: string
  handleDocumentUpload: Function
}

function Uploader({ label, handleDocumentUpload, type }: UploaderProps) {
  return (
    <UploadButton
      label={label}
      handleUpload={(acceptedFiles, rejectedFiles) =>
        handleDocumentUpload(acceptedFiles, rejectedFiles, type)
      }
    />
  )
}

export default Uploader
