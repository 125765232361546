import type { User } from '@/types/graphql'

import { Avatar, Button, Col, Popover, Row, Select, Tooltip } from 'antd'
import isEmpty from 'lodash/isEmpty'

import * as SelectWrapper from './SelectWrapper'
import { AvatarSelector, OptionLabel, OptionSubLabel } from './Styled'

interface SingleSelectProps {
  options: User[]
  selectedUser: User | undefined
  handleSelect: (value: string | null) => void
}

const SingleSelect = ({ options, selectedUser, handleSelect }: SingleSelectProps) => {
  const filterUsers = (input: string, option: any) => {
    const { props } = option

    return (
      (props.user.email && props.user.email.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
      (props.user.nickname && props.user.nickname.toLowerCase().indexOf(input.toLowerCase()) >= 0)
    )
  }

  const renderSelector = () => (
    <SelectWrapper.Wrapper>
      <SelectWrapper.Title>Assign to</SelectWrapper.Title>
      <Select
        defaultValue={selectedUser?.nickname}
        mode="combobox"
        allowClear
        placeholder="Select an assignee"
        showArrow={false}
        onSelect={handleSelect}
        filterOption={filterUsers}
        optionLabelProp="nickname"
      >
        {options.map(option => (
          <Select.Option
            key={option.uuid}
            value={option.uuid}
            // @ts-ignore
            user={option}
            nickname={option.nickname}
          >
            <Row type="flex" justify="space-around" align="middle">
              <Col span={5}>
                <Avatar src={option.picture ?? ''} />
              </Col>
              <Col span={19}>
                <OptionLabel>{option.nickname}</OptionLabel>
                <OptionSubLabel>{option.email}</OptionSubLabel>
              </Col>
            </Row>
          </Select.Option>
        ))}
      </Select>
    </SelectWrapper.Wrapper>
  )

  const renderButton = () => {
    if (isEmpty(selectedUser)) {
      return <Button icon="plus" type="dashed" shape="circle" />
    } else {
      return (
        <Tooltip title={selectedUser?.email}>
          <AvatarSelector>
            <Avatar src={selectedUser?.picture ?? ''} />
          </AvatarSelector>
        </Tooltip>
      )
    }
  }

  return (
    <Popover trigger="click" content={renderSelector()} placement="bottomRight">
      {renderButton()}
    </Popover>
  )
}

export default SingleSelect
