import type { FormComponentProps } from 'antd/lib/form'

import { useState } from 'react'
import { Mutation } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { gql } from '@apollo/client'
import { Button, Form, Icon, Input, notification, Popover } from 'antd'
import uuidv4 from 'uuid/v4'

import FormItemMapper from 'App/components/Manage/FormItemMapper'
import CurrencySelect from 'App/components/Select/CurrencySelect'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'
import EnumSelector from 'App/components/Shared/EnumSelector'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import responseHandler from 'App/utils/responseHandler'
import { Actions, Inputs, QuickCreateDiv } from './Styled'

const COMPANY_CREATE = gql`
  mutation createCompany2($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      uuid
      name
      types
      code
      tags
      country {
        name
        alpha3
      }
    }
  }
`

interface QuickCreateFormProps extends FormComponentProps {
  onChange: (uuid: string, company: any) => void
  refetch: () => void
  types: string[]
}

function QuickCreateForm(props: QuickCreateFormProps) {
  const { form, types, refetch, onChange } = props
  const { getFieldDecorator, resetFields } = props.form

  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const defaultCurrencyUuid = selectedGlobalCompany?.company?.currency?.uuid
  const defaultCountryAlpha3 = selectedGlobalCompany?.country?.alpha3

  const handleSubmit = (e, createCompany) => {
    e.preventDefault()

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          createCompany({
            variables: {
              input: {
                ...values,
                uuid: uuidv4(),
                types: values.types
              }
            }
          })
        } catch (error: any) {
          handleResponse(error.message, 'error')
        }
      }
    })
  }

  const handleCompleted = ({ createCompany }) => {
    if (createCompany) {
      notification.success({
        message: 'Submit Notice',
        description: 'Successfully created company'
      })
    }
    refetch()
    resetFields()
    onChange(createCompany.uuid, createCompany)
    handleVisibleChange(false)
  }

  const handleVisibleChange = visible => setIsVisible(visible)

  const defaultMessage = t('common.fieldRequired')

  const fields = [
    {
      cols: [
        {
          label: t('booking.companyCode'),
          value: 'code',
          input: <Input autoComplete="off" />,
          attributes: {
            rules: [{ required: true, message: defaultMessage }]
          }
        },
        {
          label: t('booking.companyName'),
          value: 'name',
          input: <Input autoComplete="off" />,
          attributes: {
            rules: [{ required: true, message: defaultMessage }]
          }
        },
        {
          label: t('booking.companyTypes'),
          value: 'types',
          input: (
            <EnumSelector
              mode="multiple"
              camelCaseToNormal
              enumName="CompanyType"
              placeholder={t('common.selectACompanyType')}
            />
          ),
          attributes: {
            initialValue: types,
            rules: [{ required: true, message: defaultMessage }]
          }
        },
        {
          label: t('common.currency'),
          value: 'preferredCurrencyUuid',
          input: <CurrencySelect />,
          attributes: {
            initialValue: defaultCurrencyUuid,
            trigger: 'onCurrencyChanged',
            valuePropName: 'selectedCurrencyUuid'
          }
        },
        {
          label: t('common.country'),
          value: 'countryAlpha3',
          input: <CountrySelect setDefaultCountry useAlpha3 />,
          attributes: {
            initialValue: defaultCountryAlpha3,
            rules: [{ required: true, message: defaultMessage }]
          }
        }
      ]
    }
  ]

  return (
    <QuickCreateDiv>
      <Popover
        trigger="click"
        visible={isVisible}
        placement="bottomRight"
        title={t('booking.createCompany')}
        onVisibleChange={handleVisibleChange}
        content={
          <Mutation mutation={COMPANY_CREATE} onCompleted={handleCompleted}>
            {(createCompany: any, { loading, error }: any) => {
              if (error) {
                responseHandler(error, 'error')
                logger.error('QuickCreateForm COMPANY_CREATE error', error)
              }

              return (
                <Inputs onSubmit={e => handleSubmit(e, createCompany)}>
                  <FormItemMapper fields={fields} getFieldDecorator={getFieldDecorator} />
                  <Actions>
                    <Button style={{ marginRight: 10 }} onClick={() => handleVisibleChange(false)}>
                      {t('common.cancel')}
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      onClick={() => handleVisibleChange(false)}
                    >
                      {loading ? t('common.hangOn') : t('common.submit')}
                    </Button>
                  </Actions>
                </Inputs>
              )
            }}
          </Mutation>
        }
      >
        <span>
          <Icon type="plus-circle" />
          {t('booking.createNew')}
        </span>
      </Popover>
    </QuickCreateDiv>
  )
}

export default Form.create()(QuickCreateForm)
