import type { BaseCompany } from '@/types/graphql'

import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Icon, Popover } from 'antd'
import { isEmpty, join, pick } from 'lodash'

import {
  GuideText,
  IconWrapper,
  NameWrapper,
  SelectedCompany,
  SelectedName,
  SelectedTypes,
  Selections,
  SelectOption
} from '@/components/Layouts/Companies/Styled'
import { changeGlobalCompany } from '@/states/reducers/globalCompany'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import webStorage from '@/utils/webStorage'

function CompaniesSelection() {
  const clearSelectedBookingsTable = useBookingStore.use.clearSelectedBookingsTable()

  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

  if (isEmpty(selectedGlobalCompany)) {
    return (
      <SelectedCompany>
        <GuideText>{t('header.noBaseCompanyFound')}</GuideText>
      </SelectedCompany>
    )
  }

  const handleClickChange = () => setVisible(!visible)

  const handleSelect = (selected?: BaseCompany) => {
    if (selected && selectedGlobalCompany.uuid !== selected.uuid) {
      clearSelectedBookingsTable()
      const baseCompany = pick(selected, ['uuid', 'name', 'slug', 'types'])

      dispatch(changeGlobalCompany(baseCompany))
      setSelectedGlobalCompany(baseCompany)

      const keys = [
        LOCAL_STORAGE_KEYS.VIEWER_DATA,
        LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES,
        LOCAL_STORAGE_KEYS.TIMELINE_VEHICLE_DEPARTMENTS,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_TABLE,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_VEHICLES_TABLE
      ]
      keys.forEach(key => webStorage.removeItem(key))
      window.location.reload()
    }
  }

  const types = selectedGlobalCompany.types || []

  return (
    <SelectedCompany>
      <SelectedTypes>{join(types, ', ')}</SelectedTypes>
      <Popover
        trigger="click"
        placement="bottomLeft"
        visible={visible}
        onVisibleChange={handleClickChange}
        // @ts-expect-error getPopopContainer is not in the type
        getPopupContainer={trigger => trigger.parentNode}
        content={
          <Selections>
            <GuideText>
              {t('header.allowedCompanies')} ({globalCompanies?.length ?? 0})
            </GuideText>
            {globalCompanies?.map(gc => {
              return (
                <SelectOption
                  key={gc.uuid}
                  onClick={() => handleSelect(gc)}
                  selected={gc.uuid === selectedGlobalCompany.uuid}
                >
                  {gc.name}
                </SelectOption>
              )
            })}
          </Selections>
        }
      >
        <SelectedName type="flex">
          <NameWrapper>{selectedGlobalCompany.name}</NameWrapper>
          <IconWrapper>
            <Icon type="edit" />
          </IconWrapper>
        </SelectedName>
      </Popover>
    </SelectedCompany>
  )
}

export default CompaniesSelection
