import { gql, useQuery } from '@apollo/client'

import { logger } from 'App/utils/logger'

interface UsePermissionIsAllowedProps {
  client: any
  resource: string
  permission: string
}

const PERMISSION_IS_ALLOWED_QUERY = gql`
  query permissionIsAllowedHook1($resource: String, $permission: String) {
    permissionIsAllowed(resource: $resource, permission: $permission)
  }
`

const usePermissionIsAllowed = ({ client, resource, permission }: UsePermissionIsAllowedProps) => {
  const { loading, data, error, refetch } = useQuery(PERMISSION_IS_ALLOWED_QUERY, {
    client,
    variables: { resource, permission },
    fetchPolicy: 'cache-first'
  })

  if (error) {
    logger.warn(
      `usePermissionIsAllowed Error resource='${resource}' permission='${permission}'.`,
      error
    )
  }

  return {
    error,
    loading,
    refetch,
    hasPermission: !!data?.permissionIsAllowed
  }
}

export default usePermissionIsAllowed
