import { Component } from 'react'
import { Query } from 'react-apollo'
import { Select } from 'antd'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import { compose, withStateHandlers } from 'recompose'

import responseHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'
import CHARGE_ITEMS_QUERY from './schema'

const Option = Select.Option

const handlers = withStateHandlers(
  {
    keyword: ''
  },
  {
    handleChange: (state, props) => (value, option) => {
      props.onChange(value, option?.props?.item || {})
    },
    handleSearch: () => value => ({ keyword: value })
  }
)

const enhance = compose(handlers)

class ChargeItemSelect extends Component {
  componentDidMount() {
    const { handleSearch, name } = this.props

    if (name) {
      handleSearch(name)
    }
  }

  render() {
    const { keyword, value, disabled = false, name, isUseCode = false } = this.props

    return (
      <Query
        query={CHARGE_ITEMS_QUERY}
        variables={{
          uuid: isUuid(value) ? value : undefined,
          query: !isUuid(value) ? value : keyword,
          statuses: this.props.statuses
        }}
      >
        {({ loading, data, error }) => {
          const chargeItems = data?.chargeItems?.rows || []

          let bindingValue = value

          if (!find(chargeItems, c => c.uuid === value || c.code === value)) {
            bindingValue = name
          }

          if (error) {
            responseHandler(error.message, 'error')
          }

          return (
            <Select
              {...this.props}
              id="cost-charge-item-selector"
              allowClear
              showSearch
              disabled={disabled}
              filterOption={false}
              value={bindingValue || value}
              onChange={this.props.handleChange}
              placeholder="Select a charge item..."
              notFoundContent={loading ? 'Searching...' : null}
              onSearch={debounce(this.props.handleSearch, 500)}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              {chargeItems.map(chargeItem => (
                <Option
                  key={isUseCode ? chargeItem.code : chargeItem.uuid}
                  value={isUseCode ? chargeItem.code : chargeItem.uuid}
                  item={chargeItem}
                >
                  {chargeItem.code ? `${chargeItem.code} - ` : ''}
                  {chargeItem.name}
                </Option>
              ))}
            </Select>
          )
        }}
      </Query>
    )
  }
}

export default enhance(ChargeItemSelect)
