import { gql } from '@apollo/client'

export const MAKE_BOOKING_MUTATION = gql`
  mutation makeBooking1($input: MakeBookingInput!) {
    makeBooking(input: $input) {
      uuid
      no
    }
  }
`

export const CHANGE_BOOKING_STATUS_SLIM = gql`
  mutation changeBookingStatusSlim1($uuid: UUID!, $status: String!, $remarks: String) {
    changeBookingStatus(uuid: $uuid, status: $status, remarks: $remarks) {
      uuid
    }
  }
`

export const BOOKING_SLIM = gql`
  query bookingForBulkImport($no: String) {
    booking(no: $no) {
      uuid
      status
      jobs {
        uuid
      }
      billTo {
        uuid
      }
      chronologies {
        date
        type
        jobUuid
        tripUuid
      }
    }
  }
`

export const UPDATE_BOOKING_SLIM = gql`
  mutation updateBookingSlim1($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      uuid
    }
  }
`

export const INSERT_CHRONOLOGY = gql`
  mutation insertChronology($input: ChronologyInput!) {
    insertChronology(input: $input) {
      uuid
      jobUuid
      tripUuid
      date
      type
      remarks
      reference
    }
  }
`

export const UPDATE_CHRONOLOGY = gql`
  mutation updateChronology($input: ChronologyUpdateInput!) {
    updateChronology(input: $input) {
      uuid
      jobUuid
      tripUuid
      date
      type
      remarks
      reference
    }
  }
`

export const REMOVE_CHRONOLOGY = gql`
  mutation removeChronology($uuid: UUID!) {
    removeChronology(uuid: $uuid) {
      success
    }
  }
`

export const ADD_JOB = gql`
  mutation addJob($input: AddJobInput!) {
    addJob(input: $input) {
      uuid
    }
  }
`

export const UPDATE_JOB = gql`
  mutation updateJob1($input: UpdateJobInput) {
    updateJob(input: $input) {
      uuid
    }
  }
`

export const UPDATE_JOB_TRIP = gql`
  mutation updateJobTrip1($input: UpdateJobTripInput) {
    updateJobTrips(input: $input) {
      success
    }
  }
`

export const DELETE_JOB = gql`
  mutation deleteJob($uuid: UUID!) {
    deleteJob(uuid: $uuid) {
      success
    }
  }
`
export const UNDELETE_JOB = gql`
  mutation undeleteJob($uuid: UUID!) {
    undeleteJob(uuid: $uuid) {
      success
    }
  }
`

export const ROLLOVER_JOB = gql`
  mutation rolloverJob($jobUuid: UUID!, $bookingUuid: UUID) {
    rolloverJob(jobUuid: $jobUuid, bookingUuid: $bookingUuid) {
      uuid
      no
      bookingUuid
    }
  }
`

export const ADD_LEG = gql`
  mutation addLeg($input: AddLegInput!) {
    addLeg(input: $input) {
      success
      message
    }
  }
`
export const UPDATE_LEG = gql`
  mutation updateLeg($input: UpdateLegInput!) {
    updateLeg(input: $input) {
      success
      message
    }
  }
`

export const DELETE_LEG = gql`
  mutation deleteLeg($uuid: UUID!) {
    deleteLeg(uuid: $uuid) {
      success
    }
  }
`

export const BOOKINGS_SEARCH_QUERY = gql`
  query bookingsSearch1($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const JOB_TYPES_QUERY = gql`
  query jobTypesSelect(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [JobTypeStatus]
    $byCompany: UUID
  ) {
    jobTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses, byCompany: $byCompany) {
      rows {
        code
        name
      }
    }
  }
`

export const BILLING_UNITS_QUERY = gql`
  query billingUnitsSelector(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BillingUnitStatus]
  ) {
    billingUnits(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        uuid
        code
        name
      }
    }
  }
`

export const TRANSPORT_JOB_TYPES_QUERY = gql`
  query transportJobTypesSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    transportJobTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`

export const CONTAINER_TYPES_QUERY = gql`
  query containerTypesSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    containerTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`

export const JOB_OPTIONS_QUERY = gql`
  query jobOptionsSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    jobOptions(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`

export const VOLUME_UNIT_QUERY = gql`
  query volumeUnitsSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    volumeUnits(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`

export const WEIGHT_UNIT_QUERY = gql`
  query weightUnitsSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    weightUnits(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`

export const CONTAINER_GRADES_QUERY = gql`
  query containerGradesSelect($limit: Int, $offset: Int, $q: String, $statuses: [String]) {
    containerGrades(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        name
      }
    }
  }
`
