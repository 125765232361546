import { FC, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import Select, { SelectProps } from 'antd/es/select'
import ApolloClient from 'apollo-client'

import { DynamicFieldQueryGraphQLArgs } from 'App/components/Booking/DynamicField/helper'
import { truncateArrayWithEllipsis } from 'App/utils/u'

interface SelectWithQueryProps extends SelectProps {
  id?: string
  fieldKey: string
  query: DynamicFieldQueryGraphQLArgs
  value?: any
  onChange?: (value: any) => void
  autoSelectFirst?: boolean
  client?: ApolloClient<any>
  showEnumValues?: boolean
  options?: any[]
}

const parseValue = ({ value, multiple }: { value: any; multiple: boolean }) => {
  let v = value
  if (!v && multiple) {
    v = []
  }
  return v
}

const SelectWithQuery: FC<SelectWithQueryProps> = ({
  id,
  query,
  autoSelectFirst = false,
  client,
  fieldKey,
  showEnumValues,
  ...props
}) => {
  const [options, setOptions] = useState(props.options || [])
  const [enumValuesGroup, setEnumValuesGroup] = useState<any>({})

  // @ts-ignore
  const [getData, { loading }] = useLazyQuery(query.query, {
    client,
    variables: query.variables || '',
    fetchPolicy: 'cache-first',
    onCompleted: res => {
      let options = query.getData ? query.getData(res) : res

      if (fieldKey === 'enumName') {
        const dupArr: string[] = []

        const groupedEnum = res?.__schema?.types?.reduce((obj, type) => {
          if (type.kind === 'ENUM') {
            dupArr.push(type.name)

            const found = obj[type.name]
            if (found) {
              found.push(type)
            } else {
              obj[type.name] = [type]
            }
          }

          return obj
        }, {})

        setEnumValuesGroup(groupedEnum)

        options = dupArr
      }

      setOptions(options)

      if (autoSelectFirst && options.length === 1 && props.onChange) {
        props.onChange(options.map(query.valueFormatter)[0])
      }
    }
  })

  useEffect(() => {
    if (!query?.query || props?.options?.filter(Boolean).length) return
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return null
  }

  return (
    <>
      <Select
        id={id}
        allowClear
        showSearch
        loading={loading}
        placeholder="Select a value..."
        {...props}
        value={parseValue({ value: props.value, multiple: props.mode === 'multiple' })}
      >
        {Array.isArray(options) &&
          options.map((option, i) => (
            <Select.Option
              key={
                query.valueFormatter
                  ? query.valueFormatter(option)
                  : query.labelFormatter
                    ? query.labelFormatter(option)
                    : option || i
              }
              value={query.valueFormatter ? query.valueFormatter(option) : option}
            >
              {query.labelFormatter ? query.labelFormatter(option) : option}
            </Select.Option>
          ))}
      </Select>

      {showEnumValues &&
        JSON.stringify(
          truncateArrayWithEllipsis(
            enumValuesGroup?.[props.value as any]?.[0].enumValues?.map((ev: any) => ev.name),
            10
          )
        )}
    </>
  )
}

// @ts-ignore
export default withApollo(SelectWithQuery)
