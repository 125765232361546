import styled from 'styled-components'

export const UploadSection = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  gap: 10px;
`

export const SectionTitle = styled.div`
  font-size: 11.5px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgb(150, 150, 150);
  padding-bottom: 5px;
  margin-bottom: 10px;
  letter-spacing: 0.3px;
`

export const EmptySection = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: rgb(120, 120, 120);
  padding: 10px 0 0;
  text-align: center;
`
