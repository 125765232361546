import { gql } from '@/types'

export const VOUCHER_FOR_JV = gql(`
  query voucherForJv($uuid: UUID!) {
    voucher(uuid: $uuid) {
      uuid
      voucherNumber
      voucherItems {
        voucherItemCn {
          uuid
        }
      }
      bookings {
        uuid
      }
    }
  }
`)

export const VOUCHERS_SELECT_QUERY = gql(`
  query vouchersSelectEs($input: VouchersSearchInput) {
    vouchersSearchJson(input: $input) {
      rows
    }
  }
`)

export const VOUCHER_ITEMS_SELECT = gql(`
  query voucherItemsSelectByVoucher($uuid: UUID) {
    voucher(uuid: $uuid) {
      voucherItems {
        uuid
        total
        booking {
          no
        }
        costItem {
          chargeItem {
            name
          }
        }
      }
    }
  }
`)

export const COST_ITEMS_SELECT = gql(`
  query costItemsSelect($uuid: UUID!) {
    costItems(uuid: $uuid) {
      uuid
      chargeItem {
        uuid
        name
      }
    }
  }
`)

export const VOUCHER_NUMBER_CN = gql(`
  query voucherNumberCn($input: voucherItemQueryInput) {
    voucherItems(input: $input) {
      uuid
      voucherItemCn {
        voucher {
          voucherNumber
        }
        costItem {
          chargeItem {
            name
          }
        }
      }
    }
  }
`)
