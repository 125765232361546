import type { CostItem, Voucher } from '@/types/graphql'

import { create } from 'zustand'

import createSelectors from './createSelectors'

type VoucherState = {
  isJvNewItems: boolean
  setIsJvNewItems: (val: boolean) => void

  isJvVoucher: boolean
  setIsJvVoucher: (val: boolean) => void

  openJvModal: boolean
  setOpenJvModal: (val: boolean) => void

  openSelectVoucherModal: boolean
  setOpenSelectVoucherModal: (val: boolean) => void

  voucherUuid: string | undefined
  setVoucherUuid: (val: string) => void

  voucherItemUuid: string | undefined
  setVoucherItemUuid: (val: string) => void

  voucher: Voucher | undefined
  setVoucher: (val: Voucher | undefined) => void

  costItemUuid: string | undefined
  setCostItemUuid: (val: string) => void

  costItem: CostItem | undefined
  setCostItem: (val: CostItem | undefined) => void

  defaultVoucherBooking: Voucher | undefined
  setDefaultVoucherBooking: (val: Voucher | undefined) => void

  defaultCostItem: CostItem | undefined
  setDefaultCostItem: (val: CostItem | undefined) => void
}

export const useVoucherStore = createSelectors(
  create<VoucherState>()(set => ({
    isJvNewItems: false,
    setIsJvNewItems: (val: boolean) => set({ isJvNewItems: val }),

    isJvVoucher: false,
    setIsJvVoucher: (val: boolean) => set({ isJvVoucher: val }),

    openJvModal: false,
    setOpenJvModal: (val: boolean) => set({ openJvModal: val }),

    openSelectVoucherModal: false,
    setOpenSelectVoucherModal: (val: boolean) => set({ openSelectVoucherModal: val }),

    voucherUuid: undefined,
    setVoucherUuid: (val: string) => set({ voucherUuid: val }),

    voucherItemUuid: undefined,
    setVoucherItemUuid: (val: string) => set({ voucherItemUuid: val }),

    voucher: undefined,
    setVoucher: (val: Voucher | undefined) => set({ voucher: val }),

    costItemUuid: undefined,
    setCostItemUuid: (val: string) => set({ costItemUuid: val }),

    costItem: undefined,
    setCostItem: (val: CostItem | undefined) => set({ costItem: val }),

    defaultVoucherBooking: undefined,
    setDefaultVoucherBooking: (val: Voucher | undefined) => set({ defaultVoucherBooking: val }),

    defaultCostItem: undefined,
    setDefaultCostItem: (val: CostItem | undefined) => set({ defaultCostItem: val })
  }))
)
