import { graphql } from 'react-apollo'

import bookingSchema from 'App/containers/booking/schema'
import schema from '../schema'

export default graphql(schema.APPROVE_VOUCHER, {
  props: ({ mutate }) => ({
    approveVoucher: params =>
      mutate({
        variables: {
          input: params
        }
      })
  })
})

export const approveVoucherWithRefetchBooking = graphql(schema.APPROVE_VOUCHER, {
  props: ({ mutate }) => ({
    approveVoucher: (params, bookingUuid) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})
