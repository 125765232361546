import { gql } from '@apollo/client'

const BOOKING_VOUCHER_COST_SHEET = gql`
  query getVoucherBookingCostSheetSlim2($uuid: UUID!) {
    booking(uuid: $uuid) {
      uuid
      quotation {
        uuid
        quotationNo
      }
      vouchers {
        # don't remove any field otherwise will bomb !
        uuid
        type
        paymentType
        status
        createdBy {
          name
          givenName
          nickname
          email
        }
        contactPerson
        description
        internalDescription
        invoiceNumber
        voucherNumber
        subTotal
        taxTotal
        total
        term
        printCount
        accountDate
        isCreditNote
        isJournalVoucher
        balance
        transactionType
        currency {
          uuid
          code
          name
        }
        vendor {
          uuid
          name
          types
          code
          tags
          debtorCode
          creditorCode
          status
          countryAlpha3
          description
        }
        customer {
          uuid
          name
          types
          code
          tags
          debtorCode
          creditorCode
          status
          countryAlpha3
          description
        }
        voucherPayments {
          uuid
          amount
          voucherUuid
          payment {
            uuid
            status
            remarks
            transactionType
            reference
            date
            amount
          }
        }
      }
      costItems {
        uuid
        bookingUuid
        description
        quantity
        size
        unit
        sellRate
        costRate
        chargeItem {
          uuid
          code
          name
          description
          sequence
          sellTax {
            percentage
          }
        }
        sellBaseRate
        sellRate
        sellTotal
        sellRate
        sellBaseRate
        sellExchangeRate
        sellCurrency {
          uuid
          code
          name
        }
        costBaseRate
        costRate
        costTotal
        costBaseRate
        costRate
        costExchangeRate
        costCurrency {
          uuid
          code
          name
        }
        accountPayable
        accountPayableDraft
        accountReceivable
        accountReceivableDraft
        cashBook
        blankCheque
        accrual
        estimatedProfit
        shortBill
        grossProfit
        isDeleted
        errors {
          msg
          field
          urgency
          expectedValue
          receivedValue
        }
      }
      costSheet {
        estimatedProfit
        grossProfit
        costTotal
        sellTotal
        accrual
        shortBill
        shortBillPercentage
        accountPayable
        accountReceivable
        accountPayableDraft
        accountReceivableDraft
      }
      processFlow {
        type
        sections {
          name
          type
          display
          steps {
            key
            helpText
            isComplete
            isCurrentStep
            permission {
              canApprove
              canReject
            }
          }
        }
      }
    }
  }
`

export default BOOKING_VOUCHER_COST_SHEET
