import type { JobType } from 'App/types/graphql'

import { format } from 'date-fns'
import { compact, identity, pick, pickBy, uniq } from 'lodash'

type JobOrTrip = 'job' | 'trip'
type FileLevel = 'booking' | 'job' | 'trip'


export const filterDetails = (details: Record<string, any>, pickFields: string[]) =>
  pick(pickBy(details, identity), pickFields) || {}

export const getRequiredFieldKeys = (
  type: JobOrTrip,
  jobType: JobType,
  filterFnc: (field) => boolean = f => !!identity(f)
) => {
  let requiredFields = jobType?.requiredFields || []
  let dynamicFields =
    type === 'job' ? jobType?.dynamicFields || [] : jobType?.tripDynamicFields || []

  requiredFields = requiredFields.filter(filterFnc)
  dynamicFields = dynamicFields.filter(filterFnc)

  return compact(uniq([...requiredFields.map(f => f?.name), ...dynamicFields.map(f => f?.key)]))
}

export const getTrimmedDetails = (
  type: JobOrTrip,
  details: Record<string, any>,
  jobType?: JobType | null
) => {
  const rawDetails = { ...(details || {}) }

  if (!jobType) return rawDetails

  const detailsFields = getRequiredFieldKeys(type, jobType)

  return filterDetails(rawDetails, detailsFields)
}

export const getTrimmedInput = (job: any, jobTypes: JobType[], values?: any, type?: any) => {
  const isValidDate = (dateString: string): boolean => {
    return !isNaN(new Date(dateString).getTime())
  }

  const formattedValues = Object.keys(values).map(key => {
    return {
      [key]: format(values[key], 'DD/MM/YYYY HH:mm')
    }
  })
  const newValues = Object.assign({}, ...formattedValues)
  const rawDetails = { ...(newValues || {}) }
  const foundJobType = jobTypes.find(jt => jt.code === job.job.type)
  if (!foundJobType) return rawDetails

  const detailsFields = [
    ...uniq(foundJobType?.requiredFields?.map(k => k?.name) as string[]),
    ...(type === 'job'
      ? (foundJobType?.dynamicFields?.filter(f => f?.type === 'DATE').map(m => m?.key) as string[])
      : (foundJobType?.tripDynamicFields?.map(k => k?.key) as string[]))
  ].filter(Boolean)

  const trimmedDetails = filterDetails(rawDetails, detailsFields)

  const filteredDetails = Object.fromEntries(
    Object.entries(trimmedDetails).filter(([key, value]) => isValidDate(value))
  )

  return filteredDetails
}


export const collectFiles = jobData => {
  const files: any = []

  // Helper function to process details object and find files
  const processDetails = (details: any, level: FileLevel, uuids: { jobUuid?: string; tripUuid?: string }) => {
    if (!details) return

    const filesInDetails = Object.entries(details)
      .filter(([key, value]) => Array.isArray(value) && value.some(item => item instanceof File))
      .map(([key, value]) => {
        return {
          ...uuids,
          key,
          level,
          files: value,
          jobIndex: level === 'booking' ? undefined : jobData.jobs.findIndex(j => j.uuid === uuids.jobUuid),
        }
      })

    files.push(...filesInDetails)
  }

  // Process booking level details
  processDetails(jobData.details, 'booking', {})
  // Process each job
  if (Array.isArray(jobData.jobs)) {
    jobData.jobs.forEach(job => {
      // Process job level details
      processDetails(job.details, 'job', { jobUuid: job.uuid })
      // Process each trip in the job
      if (Array.isArray(job.trips)) {
        job.trips.forEach(trip => {
          // Process trip level details
          processDetails(trip.details, 'trip', { jobUuid: job.uuid, tripUuid: trip.uuid })
        })
      }
    })
  }

  return files
}
