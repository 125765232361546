import type { Booking, TransportJob } from 'App/types/graphql'
import enums from 'App/types/enums'
import { BookingStatus } from 'App/types/graphql'

import { memo, useCallback, useMemo, useState, useEffect } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { ApolloClient, useQuery } from '@apollo/client'
import { Button, Divider, Row, Skeleton, Tooltip } from 'antd'
import { sortBy } from 'lodash'
import debounce from 'lodash/debounce'

import { USER_PREFERENCE_QUERY } from 'App/components/Booking/NewBookingForm/_shared/schema'
import { getUserPreferenceValue } from 'App/components/Manage/UserSettings/UserSettingsForm'
import { ErrorWrapper, NoPermissionWrapper } from 'App/components/TableView/Styled'
import JobActivityModal from 'App/components/Transport/Components/Modals/JobActivityModal/JobActivityModal'
import AddPlanVehicle from 'App/components/Transport/Planning/Vehicle/AddPlanVehicle'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { logger } from 'App/utils/logger'
import { formatLegsData } from './helpers'
import JobsTable from './JobsTable'
import { BOOKING_TRANSPORT_JOBS_QUERY } from './schema'

type BookingJobsTableProps = {
  booking: Booking
  client?: ApolloClient<object>
  query: any
  refetchBooking: () => void
  setJobTripUuids: (jobTripUuids: any) => void
}

const BookingJobsTable = memo(
  ({ booking, client, query, refetchBooking, setJobTripUuids }: BookingJobsTableProps) => {
    const { t } = useTranslation()

    const { isPortalUser } = useProcessPortalUser()
    const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

    const [visible, setVisible] = useState(false)
    const [selectedLeg, setSelectedLeg] = useState<any>()
    const [refetchTrips, setRefetchTrips] = useState(false)

    const { loading: permLoadingJob, hasPermission: hasJobPermission } = usePermissionIsAllowed({
      client,
      resource: 'job',
      permission: 'view'
    })
    const { loading: permLoadingLegs, hasPermission: hasLegsPermission } = usePermissionIsAllowed({
      client,
      resource: 'transport:job',
      permission: 'index'
    })
    const {
      loading: prefLoading,
      error: prefError,
      data: prefData
    } = useQuery(USER_PREFERENCE_QUERY, {
      client,
      variables: {
        type: enums.UserPreference.Type.SETTINGS,
        key: enums.Vehicle.Display.key
      },
      fetchPolicy: 'cache-first'
    })
    const {
      data,
      error: jobError,
      loading: jobLoading,
      refetch: refetchJob
    } = useQuery(BOOKING_TRANSPORT_JOBS_QUERY, {
      client,
      variables: {
        input: {
          limit: 100,
          jobUuids: booking?.jobs?.map((j: any) => j.uuid)
        }
      },
      fetchPolicy: 'cache-and-network'
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refetchJobsDelayed = useCallback(
      debounce(async () => {
        setTimeout(() => {
          try {
            if (refetchJob) {
              refetchJob()
            }
            if (refetchBooking) {
              refetchBooking()
            }
          } catch (error) {
            // it's ok to fail when the JobActivityModal is already unmounted
          }
        }, 500)
      }, 500),
      [refetchBooking, refetchJob]
    )

    const openLegsModal = useCallback(
      (data: any) => {
        if (isPortalUser) return // Block from opening

        setRefetchTrips(false)
        setSelectedLeg(data)
        setVisible(true)
      },
      [isPortalUser]
    )

    const handleModalCancel = useCallback(() => {
      setVisible(false)
      if (refetchTrips) {
        refetchJobsDelayed()
      }
    }, [refetchTrips, refetchJobsDelayed])

    const newSelectedLeg = useMemo(
      () => data?.transportJobs?.rows?.find((job: any) => job.legUuid === selectedLeg?.legUuid),
      [data, selectedLeg]
    )

    const vehicleCodeOrReg = useMemo(
      () =>
        getUserPreferenceValue(
          enums.UserPreference.Type.SETTINGS,
          enums.Vehicle.Display.key,
          prefData
        ),
      [prefData]
    )

    const tableReadyData = useMemo(() => {
      const transportJobs = data?.transportJobs?.rows || []

      return formatLegsData(transportJobs, vehicleCodeOrReg)
    }, [data, vehicleCodeOrReg])

    const legUuids = useMemo(() => data?.transportJobs?.rows?.map((j: any) => j.legUuid), [data])

    useEffect(() => {
      const groupedJobs = (data?.transportJobs?.rows || []).reduce(
        (acc: Record<string, TransportJob[]>, tj: TransportJob | null) => {
          if (!tj?.start) return acc

          const found = acc[tj?.jobNo || '']

          if (!Array.isArray(found)) {
            acc[tj.jobNo || ''] = []
          }

          acc[tj.jobNo || ''].push(tj)

          return acc
        },
        {}
      )

      const jobTripUuids = sortBy(Object.values(groupedJobs), t => t?.[0].jobNo).map((gj: any) => ({
        tripUuids: gj.map(t => t.tripUuid)
      }))
      setJobTripUuids(jobTripUuids)
    }, [data, setJobTripUuids])

    if (permLoadingJob || permLoadingLegs || prefLoading || jobLoading) {
      return <Skeleton active />
    }

    if (!hasJobPermission || !hasLegsPermission) {
      return <NoPermissionWrapper />
    }

    const gqlError = prefError || jobError

    if (gqlError) {
      logger.error('BookingJobsTable BOOKING_TRANSPORT_JOBS_QUERY error', gqlError)
      return <ErrorWrapper>{t('common.failedToLoadPleaseTryAgainLater')}</ErrorWrapper>
    }

    if (!booking.jobs?.length) {
      return (
        <span>
          length :{booking.jobs?.length} | rows {data?.transportJobs?.rows?.length}
          <Empty description={t('common.noData')} />
        </span>
      )
    }

    return (
      <Row style={{ paddingBottom: 15 }}>
        <>
          <Divider>
            <Row style={{ display: 'inline' }}>
              {t('transport.jobsTripsLegs')}
              <Tooltip title={t('transport.refreshTrips')}>
                <Button
                  icon="sync"
                  style={{ marginLeft: 20 }}
                  onClick={refetchJobsDelayed}
                  loading={prefLoading || jobLoading}
                />
              </Tooltip>
            </Row>
          </Divider>

          <div style={{ marginBottom: 10 }}>
            <AddPlanVehicle
              {...{
                legUuids,
                isValidForUpdate: booking?.status === BookingStatus.Accepted,
                isSelectedTable: 'LEG_BOOKING_PAGE'
              }}
            />
          </div>

          <JobsTable data={tableReadyData} readOnly={isPortalUser} openLegsModal={openLegsModal} />
        </>

        {visible && (
          <JobActivityModal
            // @ts-ignore
            visible={visible}
            leg={newSelectedLeg}
            onCancel={handleModalCancel}
            setRefetchTrips={setRefetchTrips}
            vehicleCodeOrReg={vehicleCodeOrReg}
            userBaseCompany={selectedGlobalCompany}
            query={{ startDate: query.startDate, endDate: query.endDate }}
          />
        )}
      </Row>
    )
  }
)

export default withApollo(BookingJobsTable)
