import { GroupingMethod } from "@/components/Shared/TableDataPanel/maps";
import { capitalize, isNumber, sortBy, startCase } from "lodash";

export const stringifyCellValue = (value: any) => {
  if (value && Array.isArray(value) || typeof value === 'object') {
    return JSON.stringify(value);
  }

  return value === 'null' || value === 'undefined' ? '-' : value || '-';
}

export const parseCellValue = (value: any) => {
  return JSON.parse(value);
}

export const getDisplayCellValue = (value: any) => {
  if (Array.isArray(value) && value.every(v => typeof v === 'string')) {
    return value.join(', ');
  } else if (typeof value === 'object' && value) {
    return JSON.stringify(value);
  }

  return isNumber(value) ? value : value || '-';
}

export const convertToTitle = (tableName: string) => {
  return startCase(tableName).split(' ').map(capitalize).join(' ').replace('Table', '');
}

export const replaceNullUndefinedWithEmptyString = (obj: Record<string, any>) => {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined) {
      obj[key] = ''
    } else if (typeof obj[key] === 'object') {
      replaceNullUndefinedWithEmptyString(obj[key])
    }
  }
}

export const getGroupingLookupKey = (method: GroupingMethod, key: string) => `${method} of ${key}`

export const autoBuildTableAccessors = (data: Record<string, string>[]) => {
  const headers = new Set<string>()

  data.forEach(row => {
    Object.keys(row).forEach(key => headers.add(key))
  })

  const r = Array.from(headers).reduce((acc, header) => {
    const o = {
      Header: startCase(header),
      accessor: header,
      style: { textAlign: 'left' }
    }

    acc.push(o)

    return acc
  }, [] as any[])

  const sortedHeaders = sortBy(r, ['Header'])
  const indexHeader = sortedHeaders.find(header => header.accessor === 'index')

  return indexHeader
    ? [indexHeader, ...sortedHeaders.filter(header => header.accessor !== 'index')]
    : sortedHeaders
}