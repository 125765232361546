import { useQuery } from '@apollo/client'

import { gql } from 'App/types'
import { logger } from 'App/utils/logger'

export interface useFeatureFlagProps {
  name: string
  client?: any
  baseCompanyUuid?: string
}

const IS_FEATURE_FLAGGED = gql(`
  query isFeatureFlagEnabledHook($name: String, $baseCompanyUuid: UUID) {
    isFeatureFlagEnabled(name: $name, baseCompanyUuid: $baseCompanyUuid)
  }
`)

const useFeatureFlag = ({ client, name, baseCompanyUuid }: useFeatureFlagProps) => {
  const { loading, data, error, refetch } = useQuery(IS_FEATURE_FLAGGED, {
    client,
    variables: { name, baseCompanyUuid },
    fetchPolicy: 'cache-first'
  })

  // @ts-ignore
  if (error && error?.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 403) {
    logger.warn(`useFeatureFlag Error name='${name}'`, error)
  }

  return {
    error,
    loading,
    refetch,
    isEnabled: !!data?.isFeatureFlagEnabled
  }
}

export default useFeatureFlag
