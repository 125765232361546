import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { compose, withState } from 'recompose'
import { bindActionCreators } from 'redux'

import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import { Brief, CategoryTitle } from '../Styled'
import Container from './Container'
import Preview from './Preview'
import UploadedItems from './UploadedItems'
import UploadZone from './UploadZone'

class Uploaded extends Component {
  handleOpenViewer = selected => {
    if (selected) {
      this.props.updateSelectedBookingDocument(selected)
      this.props.toggleDocumentViewer()
    }
  }

  render() {
    const {
      uploadedDocs = [],
      requiredDocs = [],
      section,
      booking,
      showDeleted,
      toggleUploadZone,
      highlightedDocuments
    } = this.props

    const hasDeleted = uploadedDocs.find(doc => doc.status === 'DELETED')

    return (
      <Container>
        <CategoryTitle>
          Documents
          {hasDeleted ? (
            <Brief
              onClick={() => {
                this.props.toggleDelete(!showDeleted)
              }}
            >
              {showDeleted ? 'Hide' : 'Show'} Deleted
            </Brief>
          ) : (
            ''
          )}
          <Button
            icon="plus"
            shape="circle"
            style={{ marginLeft: 10 }}
            onClick={() => toggleUploadZone(true)}
          />
        </CategoryTitle>
        <UploadedItems
          uploadedDocs={uploadedDocs}
          section={section}
          booking={booking}
          showDeleted={showDeleted}
          highlightedDocuments={highlightedDocuments}
          handleOpenViewer={this.handleOpenViewer}
        />
        <Preview
          state={this.props.state}
          section={this.props.section}
          showPreview={this.props.showPreview}
          togglePreview={this.props.togglePreview}
          uploadActions={this.props.uploadActions}
          selectedDocument={this.props.selectedDocument}
          toggleSelectedDocument={this.props.toggleSelectedDocument}
        />
        <UploadZone
          visible={this.props.showUploadZone}
          requiredDocs={requiredDocs}
          uploadActions={this.props.uploadActions}
          toggleUploadZone={this.props.toggleUploadZone}
        />
      </Container>
    )
  }
}

Uploaded.propTypes = {
  state: PropTypes.array,
  section: PropTypes.object,
  showPreview: PropTypes.bool,
  showDeleted: PropTypes.bool,
  toggleDelete: PropTypes.func,
  uploadedDocs: PropTypes.array,
  requiredDocs: PropTypes.array,
  uploadActions: PropTypes.object,
  togglePreview: PropTypes.func,
  showUploadZone: PropTypes.bool,
  toggleUploadZone: PropTypes.func,
  selectedDocument: PropTypes.object,
  toggleSelectedDocument: PropTypes.func
}

export default compose(
  withState('showPreview', 'togglePreview', false),
  withState('showDeleted', 'toggleDelete', false),
  withState('showUploadZone', 'toggleUploadZone', false),
  withState('selectedDocument', 'toggleSelectedDocument', {}),
  connect(
    state => ({
      selectedVoucher: state.voucher.selectedVoucher
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...bookingDocumentActions
        },
        dispatch
      )
    })
  )
)(Uploaded)
