import { Component } from 'react'
import { Query } from 'react-apollo'
import { Select } from 'antd'
import debounce from 'lodash/debounce'
import { compose, withStateHandlers } from 'recompose'
import styled from 'styled-components'

import { camelCaseToSpace } from 'App/utils/u'
import QuickCreate from './QuickCreate'
import COMPANIES_QUERY from './schema'

const Option = Select.Option

const handlers = withStateHandlers(
  { keyword: '', isBilling: false },
  {
    handleChange: (state, props) => (value, option) => {
      const company = option?.props?.company
      const billing = company?.types?.includes('billing') || false
      props.onChange(value, company)
      return { ...state, isBilling: billing }
    },
    handleSearch: () => value => ({ keyword: value })
  }
)

const enhance = compose(handlers)

const SecondaryText = styled.div`
  font-size: 12.5px;
  color: rgb(120, 120, 120);
  margin-top: 1px;
`

class CompanySelect extends Component {
  componentDidMount() {
    const { handleSearch, name } = this.props
    handleSearch(name || '')
  }

  getPopupContainer = triggerNode => triggerNode.parentNode

  render() {
    const {
      placeholder = 'Select a company...',
      allowSelectNull = false,
      portalCompanyQueryType = 'all',
      quickCreate = false,
      disabled = false,
      countryAlpha3,
      accountTypes,
      statuses,
      onChange,
      keyword,
      value,
      types,
      style,
      mode,
      disablePopupContainer = true,
      isBilling
    } = this.props

    return (
      <Query
        query={COMPANIES_QUERY}
        variables={{
          uuids: (!keyword && mode === 'multiple' && value) || null,
          query: (!mode && value) || keyword,
          countryAlpha3,
          portalCompanyQueryType,
          accountTypes,
          types,
          statuses
        }}
        fetchPolicy="cache-first"
      >
        {({ loading, data, refetch }) => {
          const companies = data?.companies?.rows || []

          let bindingValue = value

          if (allowSelectNull && value === null) {
            bindingValue = null
          }

          return (
            <>
              <Select
                id={`${this.props.formId ?? 'form-company-selector'}`}
                allowClear
                showSearch
                loading={loading}
                disabled={disabled}
                value={bindingValue}
                placeholder={placeholder}
                onChange={this.props.handleChange}
                notFoundContent={loading ? 'Searching...' : 'Not found.'}
                getPopupContainer={disablePopupContainer ? null : this.getPopupContainer}
                onSearch={debounce(this.props.handleSearch, 500)}
                mode={mode || 'default'}
                optionLabelProp="label"
                filterOption={false}
                style={style}
              >
                {allowSelectNull && (
                  <Option key="null" value={null} company={null}>
                    Null
                  </Option>
                )}

                {companies.map(company => {
                  const label = [company.code, company.name]?.filter(Boolean).join(' - ')
                  const secondaryLabel = [company.country?.alpha3]
                    .concat(company.types?.map(t => camelCaseToSpace(t)))
                    ?.filter(Boolean)
                    .join(' - ')

                  return (
                    <Option
                      key={company.uuid}
                      value={company.uuid}
                      company={company}
                      title={company.name}
                      label={label}
                    >
                      {label}
                      <SecondaryText>{secondaryLabel}</SecondaryText>
                    </Option>
                  )
                })}
              </Select>
              {quickCreate && !disabled && !isBilling && (
                <QuickCreate types={types} refetch={refetch} onChange={onChange} />
              )}
            </>
          )
        }}
      </Query>
    )
  }
}

export default enhance(CompanySelect)
