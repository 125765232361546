import { Fragment } from 'react'
import { Table } from 'antd'
import moment from 'moment'

import CompanyLabel from 'App/components/Company/CompanyLabel'
import CustomText from '../CustomText'
import { NoItemsFound, SectionTitle, StyledField, TableWrapper } from '../Styled'

const Transactions = props => {
  const { voucherBooking } = props
  const { vouchers } = voucherBooking

  const dataSource = vouchers.map(item => {
    return {
      key: item.uuid,
      details: item
    }
  })

  const columns = []

  columns.push(
    {
      title: <StyledField>Type</StyledField>,
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => {
        const transactionType = record.details.transactionType === 'ACCPAY' ? 'AP' : 'AR'
        const voucherTypePostFix = record.details.isCreditNote
          ? ' (CN)'
            : record.details.isJournalVoucher
              ? ' (JV)'
              : ''

        return <CustomText text={transactionType + voucherTypePostFix} />
      }
    },
    {
      title: <StyledField>Date</StyledField>,
      dataIndex: 'date',
      key: 'date',
      render: (text, record) => (
        <CustomText text={moment(record.details.accountDate).format('DD/MM/YYYY')} />
      )
    },
    {
      title: <StyledField>Party</StyledField>,
      dataIndex: 'party',
      key: 'party',
      render: (text, record) => {
        const isAp = record.details.transactionType === 'ACCPAY'
        const company = isAp ? record.details.vendor : record.details.customer

        return <CustomText text={<CompanyLabel company={company} />} />
      }
    },
    {
      title: <StyledField>Voucher No.</StyledField>,
      dataIndex: 'voucherNumber',
      key: 'voucherNumber',
      render: (text, record) => {
        const voucherUrl = `/vouchers/${record.details.uuid}`

        return (
          <CustomText
            isLink
            text={record.details.voucherNumber || record.details.uuid.substring(0, 8)}
            url={voucherUrl}
          />
        )
      }
    },
    {
      title: <StyledField>Status</StyledField>,
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => <CustomText text={record.details.status} />
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: (text, record) => (
        <Fragment>
          <CustomText
            text={record.details.currency && record.details.currency.code}
            muted
            noPadding
          />
          <CustomText text={record.details.total} toRight isNumeric />
        </Fragment>
      )
    }
  )

  return (
    <Fragment>
      <SectionTitle borderless>Transactions</SectionTitle>
      {!vouchers.length ? (
        <NoItemsFound align="left">No transactions available</NoItemsFound>
      ) : (
        <TableWrapper>
          <Table
            pagination={false}
            locale={{
              emptyText: 'No transactions available.'
            }}
            columns={columns}
            dataSource={dataSource}
          />
        </TableWrapper>
      )}
    </Fragment>
  )
}

export default Transactions
