import { gql } from '@/types'

export const JOBS_SELECT_OPTIONS = gql(`
  query jobSelectOptions($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        uuid
        jobNo
      }
    }
  }
`)
