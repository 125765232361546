import { MouseEvent } from 'react'
import { Link } from 'react-router-dom'
import { Button, Icon, Input, Radio, Row } from 'antd'

import FormItemMapper from 'App/components/Manage/FormItemMapper'
import { ActionItem, Actions, BackLink } from 'App/components/Manage/Styled'
import DeleteActionButton from 'App/components/Shared/DeleteActionButton'

export enum FormMode {
  Edit = 'edit',
  Create = 'create'
}

export enum GenericCrudTypeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

type CrudTypeFormProps = {
  form: any
  loading?: any
  crudType?: any
  mode?: FormMode
  backLink: string
  submitText?: string
  extraFields?: any[]
  handleSubmit: (e: MouseEvent) => void
  handleStatusChange?: (e: MouseEvent) => void
}

const RadioGroup = Radio.Group
const RadioButton = Radio.Button

const CrudTypeForm = (props: CrudTypeFormProps) => {
  const {
    form,
    mode,
    loading,
    crudType,
    backLink,
    submitText,
    handleSubmit,
    extraFields = [],
    handleStatusChange
  } = props

  const renderBackLink = () => {
    return (
      <Link to={backLink}>
        <BackLink>
          <Icon type="arrow-left" />
          Back
        </BackLink>
      </Link>
    )
  }

  const renderActions = () => {
    if (mode === FormMode.Edit) {
      return (
        <ActionItem span={18} align="right">
          <DeleteActionButton
            item={crudType}
            submitText={submitText}
            parentLoading={loading}
            handleSubmit={handleSubmit}
            handleStatusChange={handleStatusChange}
          />
        </ActionItem>
      )
    }

    return (
      <ActionItem span={18} align="right">
        <Button type="primary" loading={loading} onClick={e => handleSubmit(e)}>
          {loading ? 'Submitting...' : submitText}
        </Button>
      </ActionItem>
    )
  }

  const fields = [
    {
      cols: [
        {
          label: 'Code',
          value: 'code',
          input: <Input autoComplete="off" />,
          attributes: {
            initialValue: crudType?.code,
            rules: [
              {
                required: true,
                message: 'Code is required.'
              }
            ]
          }
        },
        {
          label: 'Name',
          value: 'name',
          input: <Input autoComplete="off" />,
          attributes: {
            initialValue: crudType?.name,
            rules: [
              {
                required: true,
                message: 'Name is required.'
              }
            ]
          }
        },
        {
          label: 'Description',
          value: 'description',
          input: <Input autoComplete="off" />,
          attributes: {
            initialValue: crudType?.description,
            rules: [
              {
                required: false,
                message: 'Description is required.'
              }
            ]
          }
        },
        {
          label: 'Sorting',
          value: 'sorting',
          input: <Input type="number" autoComplete="off" />,
          attributes: {
            initialValue: crudType?.sorting,
            rules: [
              {
                required: true,
                message: 'Sorting is required.'
              }
            ]
          }
        },
        {
          label: 'Status',
          value: 'status',
          input: (
            <RadioGroup buttonStyle="solid">
              <RadioButton value={GenericCrudTypeStatus.Active}>
                {GenericCrudTypeStatus.Active}
              </RadioButton>
              <RadioButton value={GenericCrudTypeStatus.Deleted}>
                {GenericCrudTypeStatus.Deleted}
              </RadioButton>
            </RadioGroup>
          ),
          attributes: {
            initialValue: crudType?.status || GenericCrudTypeStatus.Active
          }
        },

        ...extraFields
      ]
    }
  ]

  return (
    <>
      <Actions>
        <ActionItem span={6}>{renderBackLink()}</ActionItem>
        {renderActions()}
      </Actions>

      <Row style={{ marginBottom: 24 }}>
        <FormItemMapper fields={fields} getFieldDecorator={form.getFieldDecorator} />
      </Row>

      <Actions>
        <ActionItem span={6}>{renderBackLink()}</ActionItem>
        {renderActions()}
      </Actions>
    </>
  )
}

export default CrudTypeForm
