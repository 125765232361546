import { gql } from '@/types'

export const INVOICE_ITEMS_JOBS_QUERY = gql(`
  query jobsForInvoiceItems($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        uuid
        jobNo
      }
    }
  }
`)

export const TAX_VIEW = gql(`
  query tax($uuid: UUID!) {
    tax(uuid: $uuid) {
      uuid
      name
      code
      exemptionDocumentUuid
      percentage
    }
  }
`)

export const EXCHANGE_RATE_QUERY = gql(`
  query exchangeRates3($fromUuid: UUID, $toUuid: UUID) {
    exchangeRates(fromUuid: $fromUuid, toUuid: $toUuid) {
      rows {
        uuid
        from {
          uuid
          code
          name
        }
        to {
          uuid
          code
          name
        }
        company {
          uuid
          name
        }
        start
        end
        rate
      }
    }
  }
`)

export const EDIT_VOUCHER_ITEM_JOB_QUERY = gql(`
  query jobsEditVoucherItem($bookingUuid: UUID!) {
    jobs(bookingUuid: $bookingUuid) {
      rows {
        uuid
        status
      }
    }
  }
`)
