import type { Booking, Voucher, VoucherItem } from '@/types/graphql'
import type { ApolloClient } from '@apollo/client'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { TransactionType } from '@/types/graphql'

import { memo, useEffect, useMemo } from 'react'
import { Query, withApollo } from 'react-apollo'
import { MinusCircleFilled } from '@ant-design/icons'
import { useLazyQuery } from '@apollo/client'
import { ColumnProps } from 'antd/lib/table'
import { filter, includes } from 'lodash'
import numeral from 'numeral'

import { getRef } from 'App/utils/booking'
import { logger } from 'App/utils/logger'
import handleResponse from 'App/utils/responseHandler'
import { isUuid } from 'App/utils/u'
import { sortVoucherItems } from 'App/utils/voucher'
import CustomText from '../CustomText'
import { DeletedItem, MultiRow } from '../Styled'
import Actions from './Actions'
import withVoucherItems from './Container'
import { EXCHANGE_RATE_QUERY, INVOICE_ITEMS_JOBS_QUERY, TAX_VIEW } from './schema'
import TableView from './TableView'
import VoucherNumberCell from './voucher-number-cell'
import WarningTips from './WarningTips'

const voucherBookingsCache = {}

type VoucherItemsProps = {
  client: ApolloClient<object>
  selectedVoucher: Voucher
  booking: Booking
  selectedGlobalCompany: any
  voucherBookings: Voucher[]
  handleUpdateVoucherItem: () => void
  showDeletedVoucherItems: boolean
  handleShiftUp: () => void
  handleShiftDown: () => void
  loadingShift: boolean
  onRemoveVoucherItem: () => void
  form: WrappedFormUtils
}

const VoucherItems = memo((props: VoucherItemsProps) => {
  const {
    form,
    client,
    booking,
    loadingShift,
    selectedVoucher,
    voucherBookings,
    selectedGlobalCompany,
    showDeletedVoucherItems,
    handleShiftUp,
    handleShiftDown,
    onRemoveVoucherItem,
    handleUpdateVoucherItem
  } = props

  const voucherItems = useMemo(() => selectedVoucher?.voucherItems || [], [selectedVoucher])

  const [getJobs, { data: jobsData, error: jobsErr }] = useLazyQuery(INVOICE_ITEMS_JOBS_QUERY, {
    client,
    fetchPolicy: 'cache-and-network'
  })

  if (jobsErr) {
    handleResponse(jobsErr.message, 'error')
    logger.error('Invoice Items Table INVOICE_ITEMS_JOBS_QUERY error', jobsErr)
  }

  useEffect(() => {
    voucherItems?.length &&
      voucherItems.forEach(vi => {
        if (vi?.job?.uuid && !vi.job?.jobNo && isUuid(vi.bookingUuid)) {
          getJobs({ variables: { bookingUuid: vi.bookingUuid } })
        }
      })
  }, [voucherItems, getJobs])

  if (booking?.uuid && !voucherBookingsCache[booking?.uuid]) {
    voucherBookingsCache[booking?.uuid] = booking
  }

  voucherBookings?.length &&
    voucherBookings.forEach(booking => {
      if (booking?.uuid && !voucherBookingsCache[booking?.uuid]) {
        voucherBookingsCache[booking?.uuid] = booking
      }
    })

  let sortedVoucherItems: VoucherItem[] = sortVoucherItems(voucherItems)

  if (!showDeletedVoucherItems) sortedVoucherItems = filter(sortedVoucherItems, vi => !vi.isDeleted)

  const dataSource = sortedVoucherItems.map(item => ({
    key: item.uuid,
    details: {
      ...item,
      taxPercentage: item.taxPercentage || 0
    }
  }))

  const columns: ColumnProps<(typeof dataSource)[0]>[] = []

  if (includes(['DRAFT', 'NEW'], selectedVoucher?.status)) {
    columns.push({
      title: '',
      key: 'action',
      render: (_, record) => {
        if (record.details.isDeleted) {
          return (
            <DeletedItem>
              <MinusCircleFilled />
            </DeletedItem>
          )
        }

        return (
          <Actions
            form={form}
            booking={booking}
            loadingShift={loadingShift}
            voucherItem={record.details}
            handleShiftUp={handleShiftUp}
            handleShiftDown={handleShiftDown}
            selectedVoucher={selectedVoucher}
            onRemoveVoucherItem={onRemoveVoucherItem}
            handleUpdateVoucherItem={handleUpdateVoucherItem}
          />
        )
      }
    })
  }

  columns.push({
    title: 'Booking',
    dataIndex: 'bookingUuid',
    key: 'bookingUuid',
    width: 150,
    render: (_, record) => {
      const voucherItem = record?.details
      const bookingUuid = voucherItem?.bookingUuid || voucherItem?.costItem?.bookingUuid

      const thisBooking = voucherBookingsCache[bookingUuid]
      const type = selectedGlobalCompany?.types?.[0]
      const ref = getRef(type, thisBooking)

      return (
        <span
          onClick={() => console.log(record)}
          style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}
        >
          {`${ref || '-'} ${voucherItem?.job?.no ? `(${voucherItem.job.no})` : ''}`}
        </span>
      )
    }
  })

  columns.push({
    title: 'Job',
    dataIndex: 'jobUuid',
    key: 'jobUuid',
    render: (_, record) => {
      let jobNo = record.details?.job?.jobNo
      if (!jobNo)
        jobNo = jobsData?.jobs?.rows?.find(j => j?.uuid === record.details?.job?.uuid)?.jobNo

      return <span style={{ whiteSpace: 'pre' }}>{jobNo || '-'}</span>
    }
  })

  if (selectedVoucher?.isCreditNote || selectedVoucher?.isJournalVoucher) {
    columns.push({
      title: `Voucher Item ${selectedVoucher.isCreditNote ? 'CN' : 'JV'}`,
      key: 'voucherNumber',
      render: (_, record) => <VoucherNumberCell client={client} voucherItem={record.details} />
    })
  }

  columns.push(
    {
      title: 'Item',
      dataIndex: 'description',
      key: 'description',
      render: (desc, record) => {
        return (
          <MultiRow>
            <CustomText noPadding text={record.details?.costItem?.chargeItem?.code} />
            {' - '}
            <CustomText text={record.details?.costItem?.chargeItem?.name} />
            <br />
            {desc && <CustomText noPadding text={desc} muted multiline />}
          </MultiRow>
        )
      }
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: (_, record) => <CustomText text={record.details.quantity} />
    },
    {
      title: 'Base rate',
      dataIndex: 'currencyBaseRateExRate',
      key: 'currencyBaseRateExRate',
      align: 'right',
      render: (_, record) => {
        const currency = (record.details.currency && record.details.currency.code) || 'N/A'
        const baseRate = record.details.baseRate ?? 0
        const exchangeRate = numeral(record.details.exchangeRate).value()

        return (
          <Query
            query={EXCHANGE_RATE_QUERY}
            variables={{
              fromUuid: record.details.currency?.uuid,
              toUuid: selectedVoucher?.currency && selectedVoucher?.currency?.uuid
            }}
            skip={record?.details?.currency?.uuid === selectedVoucher?.currency?.uuid}
          >
            {({ loading, data }) => {
              if (loading) {
                return <CustomText text="Hang on..." muted />
              }

              const voucherExchangeRate = (data &&
                data.exchangeRates &&
                data.exchangeRates.rows[0]) || { rate: 1 }

              const isDiffExchangeRate =
                numeral(voucherExchangeRate && voucherExchangeRate.rate).value() !== exchangeRate
              const isMoreThanCostItem =
                baseRate >
                (selectedVoucher?.transactionType === TransactionType.Accrec
                  ? (record.details?.costItem?.sellRate ?? 0)
                  : (record.details?.costItem?.costRate ?? 0))

              return (
                <>
                  {isMoreThanCostItem && (
                    <WarningTips message="Value is more than CostItem value." />
                  )}
                  <CustomText text={currency} muted noPadding />
                  <CustomText text={baseRate} isNumeric />
                  {exchangeRate !== 1 && (
                    <>
                      <CustomText text="@" muted />
                      <CustomText
                        text={exchangeRate}
                        format="(0,0.0000)"
                        isNumeric
                        muted
                        noPadding
                      />
                      {isDiffExchangeRate && (
                        <WarningTips message="This item has different currency exchange rate." />
                      )}
                    </>
                  )}
                </>
              )
            }}
          </Query>
        )
      }
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      align: 'right',
      render: (_, record) => <CustomText text={record.details.rate} isNumeric />
    }
  )

  if (selectedVoucher?.currency?.uuid !== selectedGlobalCompany?.company?.currency?.uuid) {
    columns.push({
      title: 'Ex. Rate',
      dataIndex: 'currencyLocalExRate',
      key: 'currencyLocalExRate',
      align: 'right',
      render: (_, record) => record.details.localExchangeRate
    })
  }

  columns.push(
    {
      title: 'Tax',
      dataIndex: 'taxPercentage',
      key: 'taxPercentage',
      align: 'right',
      render: (_, record) => {
        const taxCode = record.details?.tax && record.details?.tax?.code
        const taxPercentage = record.details?.taxPercentage

        return (
          <Query
            query={TAX_VIEW}
            variables={{
              uuid: record.details.tax?.uuid || record.details?.taxUuid
            }}
          >
            {({ data }) => {
              const updatedTax = data?.tax
              const altTaxPercentage = data?.tax?.percentage
              if (updatedTax) {
                if (record.details?.tax) record.details.tax = updatedTax
              }

              return (
                <>
                  <CustomText text={`${updatedTax?.code || taxCode}`} muted />
                  <CustomText text={taxPercentage || altTaxPercentage} isNumeric noPadding />
                </>
              )
            }}
          </Query>
        )
      }
    },
    {
      title: 'Tax Total',
      dataIndex: 'taxTotal',
      key: 'taxTotal',
      align: 'right',
      render: (_, record) => <CustomText text={record.details.taxTotal} isNumeric />
    },
    {
      title: 'Sub Total',
      dataIndex: 'subTotal',
      key: 'subTotal',
      align: 'right',
      render: (_, record) => <CustomText text={record.details.subTotal} isNumeric />
    },
    {
      title: 'Profit',
      key: 'profit',
      align: 'right',
      render: (_, record) => {
        const quantity = record.details.quantity ?? 1
        const sellRate = record.details?.costItem?.sellRate ?? 1
        const costRate = record.details?.costItem?.costRate ?? 1

        const profit = quantity * sellRate - quantity * costRate

        return <CustomText text={profit} isNumeric />
      }
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      align: 'right',
      render: (_, record) => <CustomText text={record.details.total} isNumeric />
    }
  )

  // @ts-ignore
  return <TableView columns={columns} dataSource={dataSource} selectedVoucher={selectedVoucher} />
})

export default withApollo(withVoucherItems(VoucherItems))
