import type { ProcessFlowDocumentType } from '@/types/graphql'

import { Button, Modal } from 'antd'
import filter from 'lodash/filter'

import Uploader from '@/components/Workflow/Uploaded/UploadZone/Uploader'
import { documentType } from '@/utils/labelMap'
import { EmptySection, SectionTitle, UploadSection } from './Styled'

type UploadZoneProps = {
  visible: boolean
  toggleUploadZone: (visible: boolean) => void
  requiredDocs: ProcessFlowDocumentType[] | any
  uploadActions: any
}

function UploadZone(props: UploadZoneProps) {
  const { visible, toggleUploadZone, requiredDocs, uploadActions } = props

  const mandatoryDocs = filter(requiredDocs, doc => doc.required) || []
  const nonMandatoryDocs = filter(requiredDocs, doc => !doc.required) || []

  return (
    <Modal
      width="700px"
      title="Upload Documents"
      okText="Done"
      visible={visible}
      footer={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button href="/bookings/new/upload-document">Bulk Upload</Button>
          <Button type="primary" onClick={() => toggleUploadZone(false)}>
            Done
          </Button>
        </div>
      }
      onCancel={() => toggleUploadZone(false)}
    >
      <SectionTitle>Required Documents</SectionTitle>
      <UploadSection>
        {mandatoryDocs.length === 0 && <EmptySection>No documents available.</EmptySection>}

        {mandatoryDocs.map((doc: ProcessFlowDocumentType) => {
          const uploadLabel = doc.type && documentType[doc.type]
          return (
            <Uploader
              key={doc.type}
              type={doc.type!}
              label={uploadLabel || doc.type}
              handleDocumentUpload={uploadActions.handleDocumentUpload}
            />
          )
        })}
      </UploadSection>

      <SectionTitle>Optional Documents</SectionTitle>
      <UploadSection>
        {nonMandatoryDocs.length === 0 && <EmptySection>No documents available.</EmptySection>}
        {nonMandatoryDocs.map((doc: ProcessFlowDocumentType) => {
          const uploadLabel = doc.type ? documentType[doc.type] : undefined

          return (
            <Uploader
              key={doc.type}
              type={doc.type!}
              label={uploadLabel || doc.type}
              handleDocumentUpload={uploadActions.handleDocumentUpload}
            />
          )
        })}
      </UploadSection>
    </Modal>
  )
}

export default UploadZone
