import type { ApolloClient } from '@apollo/client'
import type { SelectProps } from 'antd/lib/select'
import { VoucherStatus } from '@/types/graphql'

import { forwardRef, memo, Ref, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'
import debounce from 'lodash/debounce'

import { VOUCHERS_SELECT_QUERY } from '@/components/Select/schema'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'

const { Option } = Select

type VouchersSelectProps = SelectProps & {
  client?: ApolloClient<object>
  queryOnMount?: boolean
  bookingUuid?: string
  excludedVouchers?: string[]
  customerUuid?: string
  vendorUuid?: string
}

const VouchersSelect = forwardRef((props: VouchersSelectProps, ref: Ref<Select>) => {
  const {
    client,
    value,
    mode,
    queryOnMount,
    bookingUuid,
    excludedVouchers,
    customerUuid,
    vendorUuid,
    placeholder = 'Search voucher...'
  } = props

  const [vouchers, setVouchers] = useState<any[]>([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(() => {
    return {
      q: searchInput || '',
      limit: 20,
      offset: 0,
      filter: {
        bookingUuid: bookingUuid && isUuid(bookingUuid) ? [bookingUuid] : [],
        voucherUuid: !mode && value && isUuid(value as string) ? [value] : [],
        status: [
          VoucherStatus.Approved,
          VoucherStatus.PartiallyPaid,
          VoucherStatus.Paid,
          VoucherStatus.Deleted,
          VoucherStatus.Voided
        ],
        customerUuid: customerUuid && isUuid(customerUuid) ? [customerUuid] : [],
        vendorUuid: vendorUuid && isUuid(vendorUuid) ? [vendorUuid] : []
      }
    }
  }, [searchInput, bookingUuid, mode, value, customerUuid, vendorUuid])

  const [getVouchers, { data, error, loading }] = useLazyQuery(VOUCHERS_SELECT_QUERY, {
    client,
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (queryOnMount) getVouchers()
  }, [queryOnMount, getVouchers, value])

  useEffect(() => {
    const filteredVouchers = data?.vouchersSearchJson?.rows?.filter(
      voucher => voucher.voucherNumber !== null
    )

    if (excludedVouchers?.length) {
      setVouchers(
        filteredVouchers?.filter(voucher => {
          return !excludedVouchers.includes(voucher.uuid)
        })
      )
    } else {
      setVouchers(filteredVouchers)
    }
  }, [data, excludedVouchers])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce((userInput: string) => {
      setSearchInput(userInput)
      getVouchers()
    }, 500),
    []
  )

  if (error) {
    logger.error('VouchersSelect VOUCHER_SEARCH_QUERY error', error)
    responseHandler(error, 'error')
  }

  return (
    <Select
      {...props}
      allowClear
      showSearch
      ref={ref}
      loading={loading}
      filterOption={false}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      onSearch={handleSearch}
      placeholder={placeholder}
    >
      {vouchers?.map(voucher => (
        <Option key={voucher.uuid} value={voucher.uuid}>
          {voucher.voucherNumber}
        </Option>
      ))}
    </Select>
  )
})

export default withApollo(memo(VouchersSelect))
