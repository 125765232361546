import { gql } from '@/types'

export const BOOKINGS_MAIN_QUERY = gql(`
  query bookingsMainEs($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
