import { useState } from 'react'
import { withApollo } from 'react-apollo'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Icon, Modal, Popover } from 'antd'

import { getBkQueryVariables } from 'App/components/Booking/bookingHelper'
import BookingDocumentQuery from 'App/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import schema from 'App/containers/booking/schema'
import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import EmptyAnchor from '../../EmptyAnchor'
import { DELETE_BOOKING_DOCUMENT } from '../Content/schema'
import { StyledMenu, StyledMenuItem, StyledOptions } from './Styled'

const { confirm } = Modal

const HeaderOptions = ({ bookingDocument, client }) => {
  const [visible, setVisible] = useState(false)
  const dispatch = useDispatch()
  const { params } = useParams()

  const [deleteDocument] = useMutation(DELETE_BOOKING_DOCUMENT, {
    client,
    refetchQueries: [
      {
        query: schema.BOOKING_QUERY,
        variables: getBkQueryVariables(params)
      },
      {
        query: BookingDocumentQuery,
        variables: { uuid: bookingDocument.uuid }
      }
    ],
    onCompleted: result => handleDeleted(result)
  })

  const handleDelete = () => {
    setVisible(false)
    confirm({
      title: 'Confirm to delete this document?',
      content: 'All changes or links to this document will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDocument({
          variables: {
            uuid: bookingDocument.uuid
          }
        })
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const handleDeleted = ({ deleteBookingDocument }) => {
    dispatch(
      bookingDocumentActions.updateSelectedBookingDocument({
        ...deleteBookingDocument,
        allowedApprovals: bookingDocument.allowedApprovals
      })
    )
  }

  const renderOptions = () => {
    return (
      <StyledMenu>
        <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}`}>
          View in new tab
        </StyledMenuItem>
        <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}?tag=1`}>
          View Tagged in new tab
        </StyledMenuItem>
        {bookingDocument.status === 'DELETED' ? null : (
          <StyledMenuItem onClick={handleDelete}>Delete document</StyledMenuItem>
        )}
      </StyledMenu>
    )
  }

  return (
    <StyledOptions>
      <Popover
        trigger="click"
        placement="bottomLeft"
        content={renderOptions()}
        getPopupContainer={trigger => trigger.parentNode}
        visible={visible}
      >
        <EmptyAnchor>
          <Icon
            type="ellipsis"
            onClick={() => setVisible(!visible)}
            style={{ cursor: 'pointer' }}
          />
        </EmptyAnchor>
      </Popover>
    </StyledOptions>
  )
}

export default withApollo(HeaderOptions)
