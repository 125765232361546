import { VoucherItem } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { ApolloClient } from '@apollo/client'

import { VOUCHER_NUMBER_CN } from '@/components/Select/schema'
import CustomText from '@/components/Voucher/CustomText'

const VoucherNumberCell = ({
  voucherItem,
  client
}: {
  voucherItem: VoucherItem
  client: ApolloClient<object>
}) => {
  const [voucherNumber, setVoucherNumber] = useState(
    voucherItem.voucherItemCn?.voucher?.voucherNumber
  )
  const [chargeItem, setChargeItem] = useState(
    voucherItem.voucherItemCn?.costItem?.chargeItem?.name
  )

  useEffect(() => {
    if (voucherNumber || !client) return

    async function getVoucherNumber() {
      const voucherItemQuery = await client.query({
        query: VOUCHER_NUMBER_CN,
        variables: { input: { bookingUuids: [voucherItem.booking?.uuid] } }
      })
      const foundVoucherItem = voucherItemQuery.data.voucherItems?.find(
        vi => vi?.uuid === voucherItem.uuid
      )

      if (foundVoucherItem) {
        setVoucherNumber(foundVoucherItem.voucherItemCn?.voucher?.voucherNumber)
        setChargeItem(foundVoucherItem.voucherItemCn?.costItem?.chargeItem?.name)
      }
    }

    getVoucherNumber()
  }, [voucherNumber, client, voucherItem])

  return <CustomText noPadding text={`${voucherNumber ?? '-'}`} />
}

export default VoucherNumberCell
