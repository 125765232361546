import { Input } from 'antd'
import { InputProps } from 'antd/lib/input'
import { ChangeEvent } from 'react'

interface DynamicInputTextProps extends InputProps {
  isCapitalize: boolean
  value?: any
}

const DynamicInputText = (props: DynamicInputTextProps) => {
  const { isCapitalize, onChange, placeholder = `Enter text`, ...otherProps } = props
  return (
    <Input
      autoComplete="off"
      {...otherProps}
      placeholder={placeholder}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const val = isCapitalize ? e.target.value.toUpperCase() : e.target.value
        // @ts-ignore
        onChange(val)
      }}
    />
  )
}

export default DynamicInputText