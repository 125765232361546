import { forwardRef, Fragment } from 'react'
import { Col, Form } from 'antd'
import { WrappedFormUtils } from 'antd/lib/form/Form'

import { ColWrapper, Divider } from 'App/components/Manage/FormItemMapper/Styled'

const FormItem = Form.Item

type FormItemLayout = {
  labelCol?: { xs: { span: number }; sm: { span: number } }
  wrapperCol?: { xs: { span: number }; sm: { span: number } }
}

type FormItemAttributes = {
  initialValue?: any
  rules?: any[] // Array<{ required: boolean, message: string}>
  valuePropName?: string
}

export type FormItemConfig = FormItemLayout & {
  key?: string
  isCustom?: boolean
  removeDecorator?: boolean
  label: string
  value: string
  input: JSX.Element
  attributes: FormItemAttributes
}

export type FormField = {
  cols: FormItemConfig[]
  label?: string
  key?: string
  combine?: boolean
}

export const formItemLayout: FormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

type FormItemMapperProps = {
  fields: FormField[]
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
  headerTitle?: string
}

const FormItemMapper = forwardRef<HTMLDivElement, FormItemMapperProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ fields, getFieldDecorator, headerTitle }, ref) => {
    return (
      <>
        <div style={{ paddingLeft: '15px', margin: '20px 0' }}>
          {headerTitle && <b>{headerTitle}</b>}
        </div>
        {fields?.map((field, fieldIndex) => {
          if (field.combine) {
            return (
              <FormItem key={field.key ?? fieldIndex} label={field.label}>
                {field.cols.map((col, colIndex) => (
                  <FormItem
                    key={col.key ?? colIndex}
                    labelCol={col.labelCol || formItemLayout.labelCol}
                    wrapperCol={col.wrapperCol || formItemLayout.wrapperCol}
                  >
                    {getFieldDecorator(col.value as never, col.attributes)(col.input)}
                  </FormItem>
                ))}
              </FormItem>
            )
          }

          return (
            <Col key={field.key ?? fieldIndex}>
              {field.label && <Divider>{field.label}</Divider>}
              {/* @ts-ignore */}
              <ColWrapper full={field.full} width={field.width}>
                {field.cols.map((col, colIndex) => {
                  if (col.isCustom) {
                    return (
                      <Fragment key={col.key ?? colIndex}>
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value as never, col.attributes)(col.input)}
                      </Fragment>
                    )
                  } else {
                    return (
                      <FormItem
                        key={col.key ?? colIndex}
                        label={col.label}
                        labelCol={col.labelCol || formItemLayout.labelCol}
                        wrapperCol={col.wrapperCol || formItemLayout.wrapperCol}
                      >
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value as never, col.attributes)(col.input)}
                      </FormItem>
                    )
                  }
                })}
              </ColWrapper>
            </Col>
          )
        })}
      </>
    )
  }
)

export default FormItemMapper
