import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from '@apollo/client'
import { Button, Checkbox, Popover } from 'antd'
import styled from 'styled-components'

import {
  UPDATE_USER_PREFERENCE_MUTATION,
  USER_PREFERENCE_QUERY
} from 'App/components/Booking/NewBookingForm/_shared/schema'
import { getUserPreferenceValue } from 'App/components/Manage/UserSettings/UserSettingsForm'
import { getColumns } from './columns'

type SetColumnsProps = {
  client?: any
  columns: any
  setColumns: Dispatch<SetStateAction<any>>
}

const StyledCheckbox = styled(Checkbox.Group)`
  .ant-checkbox-group-item {
    display: block;
  }
`

function SetColumns({ columns = [], setColumns, client }: SetColumnsProps) {
  const { t } = useTranslation()
  const [isVisible, setIsVisible] = useState<boolean>(false)

  const defaultColumns = getColumns()
  const [updateUserPreference] = useMutation(UPDATE_USER_PREFERENCE_MUTATION, { client })

  const { loading: prefLoading, data: prefData } = useQuery(USER_PREFERENCE_QUERY, {
    client,
    variables: { type: 'BOOKINGS_JOBS_TABLE', key: 'COLUMNS' },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (!prefLoading && prefData) {
      const columnsPreference = getUserPreferenceValue('BOOKINGS_JOBS_TABLE', 'COLUMNS', prefData)
      if (columnsPreference) {
        setColumns(columnsPreference)
      }
    }
  }, [prefData, prefLoading, setColumns])

  if (!columns) return null

  const listOfColumns = columns?.map((col: any) => col.accessor)
  const listOfDefaultColumns = defaultColumns.map((col: any) => col.accessor)

  const onChange = async checkedValues => {
    const newColumns = defaultColumns.filter((col: any) => checkedValues.includes(col.accessor))
    setColumns(newColumns)

    await updateUserPreference({
      variables: {
        type: 'BOOKINGS_JOBS_TABLE',
        key: 'COLUMNS',
        value: JSON.stringify(newColumns)
      }
    })
  }

  const menu = (
    <StyledCheckbox
      options={listOfDefaultColumns}
      defaultValue={listOfColumns}
      onChange={onChange}
    />
  )

  return (
    <Popover
      content={menu}
      trigger="click"
      visible={isVisible}
      placement="bottomLeft"
      onVisibleChange={() => setIsVisible(!isVisible)}
    >
      <Button style={{ marginBottom: 15 }}>{t('transport.planning.byVehicle.setColumns')}</Button>
    </Popover>
  )
}

export default withApollo(SetColumns)
