import React from 'react';
import { Timeline, Typography } from 'antd';
import { getActTime } from '@/components/RelativeDateTime';
import './StatTimeline.css'; // Import the CSS file

const { Text } = Typography;

type StatTimelineProps = {
  stat: Array<{
    i: number;
    t: string;
    ts: string;
    meta?: {
      drId?: string;
      vCode?: string;
      trId?: string;
    };
  }>;
};

const StatTimeline = ({ stat = [] }: StatTimelineProps) => {
  return (
    <Timeline className="dense-timeline">
      {stat?.map((item) => (
        <Timeline.Item key={`${item.t}-${item.ts}`} className="dense-timeline-item">
          <Text strong className="small-text">{item.t}</Text> - <Text type="secondary" className="small-text">[{getActTime(item.ts)}]</Text>
          {item.meta && (
            <Text type="secondary" className="small-text">
              {' '}
              ({[item.meta.drId, item.meta.vCode, item.meta.trId].filter((x) => x).join(' - ')})
            </Text>
          )}
        </Timeline.Item>
      ))}
    </Timeline>
  );
};

export default StatTimeline;