import { memo, useCallback, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'
import { faAppStoreIos, faGooglePlay } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Button, Dropdown, Icon, Menu, Popover } from 'antd'
import { includes } from 'lodash'
import { compose } from 'recompose'

import { handleCheckUpdate } from '@/components/CacheBuster'
import Logout from '@/components/Logout'
import FeatureGuard from '@/components/Shared/FeatureGuard'
import config from '@/config'
import { withViewer } from '@/contexts/viewer'
import useBaseCompanyType from '@/hooks/useBaseCompanyType'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useWindowDimensions from '@/hooks/useWindowDimensions'
import i18n, { languageOptions } from '@/libs/i18n'
import { withRouter } from '@/RouterHOC'
import EntitySystemLink from './EntitySystemLink'
import {
  AppVersion,
  NavItem,
  RightNav,
  Separator,
  SettingItem,
  SettingItemLanguage,
  SettingsView,
  SubNavItem,
  UserEmail,
  UserProfile
} from './Styled'

const { SubMenu } = Menu

const HeaderOptions = ({ client, loggedInUser, router }) => {
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const { data = [] } = useBaseCompanyType(client)

  const { isPortalUser, baseCompanyUuids } = useProcessPortalUser(loggedInUser)

  const [visible, setVisible] = useState(false)
  const [versionDisplay, setVersionDisplay] = useState(config.version)

  const { pathname = '' } = router

  const handleClickChange = useCallback(visible => setVisible(visible), [])

  const noUpdates = useCallback(() => setVersionDisplay('App up-to-date'), [])
  const revertState = useCallback(() => setVersionDisplay(config.version), [])
  const initState = useCallback(() => setVersionDisplay('Checking for updates'), [])

  const changeLanguage = language => i18n.changeLanguage(language)

  const renderSettings = useCallback(() => {
    return (
      <SettingsView>
        <UserProfile>
          <Avatar src={loggedInUser?.picture} />
          <UserEmail>{loggedInUser?.email}</UserEmail>
          <AppVersion onClick={() => handleCheckUpdate(initState, noUpdates, revertState)}>
            {versionDisplay}
          </AppVersion>
        </UserProfile>

        <Separator />

        {loggedInUser?.isSA && (
          <>
            <SettingItem>
              <Link to="/superadmin/base-companies">SuperAdmin</Link>
            </SettingItem>
            <Separator />
          </>
        )}

        {baseCompanyUuids?.length > 0 && !isPortalUser && (
          <>
            <SettingItem>
              <EntitySystemLink />
            </SettingItem>
            <Separator />
          </>
        )}

        {baseCompanyUuids?.length > 0 && (
          <>
            <SettingItem>
              <Link to="/manage/portal-company">{t('header.manage')}</Link>
            </SettingItem>

            <Separator />

            <SettingItem>
              <a
                href="https://shipxhelp.freshdesk.com/support/home"
                target="_blank"
                rel="noreferrer"
              >
                {t('header.help')}
              </a>
            </SettingItem>

            <Separator />

            <SettingItem>
              <span className="inline-icon">
                {t('header.driverApp')}
                <a
                  href="https://play.google.com/store/apps/details?id=my.com.swiftlogistics.transport"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faGooglePlay} />
                </a>
                <a
                  href="https://apps.apple.com/us/app/shipx-transport/id1616628481"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon icon={faAppStoreIos} />
                </a>
              </span>
            </SettingItem>
            <Separator />

            <SettingItemLanguage>
              <span>{t('header.language')}</span>
              <div
                style={{
                  maxHeight: 20,
                  gap: 20,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                {languageOptions.map(({ label, value }) => {
                  const isSelected = i18n.language === value
                  return (
                    <p
                      key={value}
                      style={{ cursor: 'pointer', color: isSelected ? '#1890FF' : '#151b26' }}
                      onClick={() => changeLanguage(value)}
                    >
                      {label}
                    </p>
                  )
                })}
              </div>
            </SettingItemLanguage>
            <Separator />
          </>
        )}

        <SettingItem>
          <Logout />
        </SettingItem>
      </SettingsView>
    )
  }, [
    loggedInUser,
    versionDisplay,
    baseCompanyUuids?.length,
    isPortalUser,
    t,
    initState,
    noUpdates,
    revertState
  ])

  const transportMenu = useMemo(
    () => (
      <SubNavItem>
        <FeatureGuard name="LC PLANNING">
          <Menu.Item selected={includes(pathname, '/pre-planning')}>
            <Link to="/pre-planning">{t('header.prePlanning')}</Link>
          </Menu.Item>
        </FeatureGuard>
        <SubMenu title={t('header.planning')}>
          <Menu.Item selected={includes(pathname, '/transport/planning/unplanned')}>
            <Link to="/transport/planning/unplanned">{t('header.unplanned')}</Link>
          </Menu.Item>
          <Menu.Item selected={includes(pathname, '/transport/planning')}>
            <Link to="/transport/planning">{t('header.byLegs')}</Link>
          </Menu.Item>
          <Menu.Item selected={includes(pathname, '/transport/planning/vehicle')}>
            <Link to="/transport/planning/vehicle">{t('header.byVehicle')}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title={t('header.operations')}>
          <Menu.Item selected={includes(pathname, '/transport/operation')}>
            <Link to="/transport/operation">{t('header.tracking')}</Link>
          </Menu.Item>
          <Menu.Item selected={includes(pathname, '/transport/operation')}>
            <Link to="/transport/manifests">{t('header.manifests')}</Link>
          </Menu.Item>
        </SubMenu>
        <SubMenu title={t('header.incentives')}>
          <Menu.Item selected={includes(pathname, '/transport/operation/incentiveShift')}>
            <Link to="/transport/operation/incentiveShift">{t('header.shifts')}</Link>
          </Menu.Item>
          <Menu.Item selected={includes(pathname, '/transport/operation/incentiveVoucher')}>
            <Link to="/transport/operation/incentiveVoucher">{t('header.incentiveVoucher')}</Link>
          </Menu.Item>
        </SubMenu>
      </SubNavItem>
    ),
    [pathname, t]
  )

  const dashboardMenu = useMemo(
    () => (
      <SubNavItem>
        <Menu.Item selected={includes(pathname, '/transport/dashboard')}>
          <Link to="/transport/dashboard">{t('header.overview')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/reports')}>
          <Link to="/reports">{t('header.reports')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/dashboard')}>
          <Link to="/dashboard">{t('header.bookingStatus')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/transport/monitoring/job-status')}>
          <Link to="/transport/monitoring/job-status">{t('header.jobStatus')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/transport/monitoring/trip-status')}>
          <Link to="/transport/monitoring/trip-status">{t('header.tripStatus')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/transport/monitoring/empty-trips')}>
          <Link to="/transport/monitoring/empty-trips">{t('header.emptyTrips')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/transport/monitoring/leg-activities-report')}>
          <Link to="/transport/monitoring/leg-activities-report">{t('header.legActivities')}</Link>
        </Menu.Item>
        <Menu.Item selected={includes(pathname, '/bulk-functions')}>
          <Link to="/bulk-functions">{t('header.bulkFunctions')}</Link>
        </Menu.Item>
        <FeatureGuard name="DOCUMENT GENERATOR">
          <Menu.Item selected={includes(pathname, '/documents')}>
            <Link to="/documents">{t('header.documents')}</Link>
          </Menu.Item>
        </FeatureGuard>
      </SubNavItem>
    ),
    [pathname, t]
  )

  const isTransport = useMemo(
    () => !isPortalUser && !!data.find(d => d.meta?.type === 'TRANSPORT'),
    [isPortalUser, data]
  )

  const mobileMenu = useCallback(
    () => (
      <Menu>
        <Menu.Item className="mb-0">
          <Link to="/bookings/new" className="flex flex-row gap-5">
            <PlusOutlined /> <span>{t('header.newBooking')}</span>
          </Link>
        </Menu.Item>
        {isTransport && (
          <SubMenu title="Transport">
            <SubMenu title={t('header.planning')}>
              <FeatureGuard name="LC PLANNING">
                <Menu.Item>
                  <Link to="/pre-planning">{t('header.prePlanning')}</Link>
                </Menu.Item>
              </FeatureGuard>
              <Menu.Item>
                <Link to="/transport/planning/unplanned">{t('header.unplanned')}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/transport/planning">{t('header.byLegs')}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/transport/planning/vehicle">{t('header.byVehicle')}</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu title={t('header.operations')}>
              <Menu.Item>
                <Link to="/transport/operation">{t('header.tracking')}</Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu title={t('header.incentives')}>
              <Menu.Item>
                <Link to="/transport/operation/incentiveShift">{t('header.shifts')}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/transport/operation/incentiveVoucher">
                  {t('header.incentiveVoucher')}
                </Link>
              </Menu.Item>
            </SubMenu>
          </SubMenu>
        )}
        <Menu.Item>
          <Link to="/vouchers">Invoices</Link>
        </Menu.Item>
        {!isPortalUser && (
          <SubMenu title="Dashboard">
            <Menu.Item>
              <Link to="/transport/dashboard">{t('header.overview')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/reports">{t('header.reports')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/dashboard">{t('header.bookingStatus')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/transport/monitoring/job-status">{t('header.jobStatus')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/transport/monitoring/trip-status">{t('header.tripStatus')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/transport/monitoring/empty-trips">{t('header.emptyTrips')}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/transport/monitoring/leg-activities-report">
                {t('header.legActivities')}
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/bulk-functions">{t('header.bulkFunctions')}</Link>
            </Menu.Item>
          </SubMenu>
        )}
        <SubMenu title="More">
          {loggedInUser?.isSA && (
            <Menu.Item>
              <Link to="/superadmin/base-companies">{t('header.superAdmin')}</Link>
            </Menu.Item>
          )}
          {!isPortalUser && (
            <Menu.Item>
              <EntitySystemLink />
            </Menu.Item>
          )}
          <Menu.Item>
            <Link to="/manage/portal-company">{t('header.manage')}</Link>
          </Menu.Item>
          <Menu.Item>
            <a href="https://shipxhelp.freshdesk.com/support/home" target="_blank" rel="noreferrer">
              Help
            </a>
          </Menu.Item>
          <Menu.Item>
            <Logout />
          </Menu.Item>
        </SubMenu>
      </Menu>
    ),
    [t, isTransport, isPortalUser, loggedInUser?.isSA]
  )

  return (
    <RightNav type="flex" justify="end" align="middle" style={{ maxHeight: 58 }} gutter={15}>
      {width < 770 ? (
        <>
          <Dropdown overlay={mobileMenu} trigger={['click']}>
            <Button style={{ marginRight: 10 }} onClick={e => e.preventDefault()}>
              <Icon type="menu" />
            </Button>
          </Dropdown>
        </>
      ) : (
        <>
          {baseCompanyUuids?.length > 0 && (
            <NavItem selected={includes(pathname, '/bookings/new')}>
              <Link to="/bookings/new">
                <Button id="create-booking-button" type="ghost">
                  <PlusOutlined />
                  {t('header.newBooking')}
                </Button>
              </Link>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && isTransport && (
            <NavItem selected={includes(pathname, '/transport/')}>
              <Dropdown overlay={transportMenu}>
                <div className="ant-dropdown-link">
                  {t('header.transport')} <Icon type="down" />
                </div>
              </Dropdown>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && (
            <NavItem selected={includes(pathname, '/vouchers')}>
              <Link to="/vouchers">{t('header.invoices')}</Link>
            </NavItem>
          )}

          {baseCompanyUuids?.length > 0 && !isPortalUser && (
            <NavItem selected={includes(pathname, '/transport/dashboard')}>
              <Dropdown overlay={dashboardMenu}>
                <div className="ant-dropdown-link">
                  {t('header.dashboard')} <Icon type="down" />
                </div>
              </Dropdown>
            </NavItem>
          )}

          <NavItem>
            <Popover
              visible={visible}
              placement="bottomRight"
              content={renderSettings()}
              onVisibleChange={handleClickChange}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Icon
                type="user"
                style={{
                  fontSize: 20,
                  color: 'rgb(140,140,140)',
                  cursor: 'pointer',
                  paddingRight: 5,
                  paddingBottom: '0.5rem'
                }}
              />
            </Popover>
          </NavItem>
        </>
      )}
    </RightNav>
  )
}

export default compose(withApollo, withRouter, withViewer)(memo(HeaderOptions))
